import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";

import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import { ReactComponent as GroupIcon } from "../../../assets/Group.svg";
import {
  h4Medium,
  h5Medium,
  h6Medium,
  h6Regular,
  h7Regular,
  pointer,
} from "../../../shared/constants";

const useStyles = makeStyles({
  container: {
    width: 340,
    height: "auto",
    background: "#FFFFFF",
    boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
    borderRadius: 32,
    padding: 40,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    ...h4Medium,
    marginBottom: 16,
  },
});

const VerifyNumber = ({
  phoneNumber = "000000000",
  onChangeNumber,
  handleClose,
  onVerify,
  onResend
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [digits, setDigits] = useState({
    a: "",
    b: "",
    c: "",
    d: "",
    d: "",
    e: ""
  });

  const inputRefs = {
    a: useRef(null),
    b: useRef(null),
    c: useRef(null),
    d: useRef(null),
    e: useRef(null),
    f: useRef(null),
  };

  const inputDigitStyle = {
    border: "none",
    borderBottom: "1px solid lightgrey",
    background: "none",
    outline: "none",
    maxWidth: 30,
    padding: 10,
    ...h5Medium,
    textAlign: "center"
  }

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (
      digits.a &&
      digits.b &&
      digits.c &&
      digits.d &&
      digits.e &&
      digits.f &&
      isValidPhoneNumber(phoneNumber)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digits]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    if (value?.length !== 1) return;

    setDigits({ ...digits, [name]: value });

    if (value && name !== "f") {
      inputRefs[String.fromCharCode(name.charCodeAt(0) + 1)].current.focus();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header} style={{ direction: t("css.side") }}>
        <span>{t("verifyNumber.verifyYourNumber")}</span>
        <span
          style={{ ...pointer }}
          onClick={() => {
            handleClose();
          }}
        >
          <RemoveIcon style={{ height: 24, width: 24 }} />
        </span>
      </div>

      <div style={{ ...h6Regular, marginBottom: 16 }}>
        {t("verifyNumber.textMessage")}
        <span> {phoneNumber} </span>
      </div>

      <div
        style={{
          ...h6Medium,
          color: "#80BB34",
          marginBottom: 30,
          direction: t("css.side"),
          ...pointer,
        }}
        onClick={() => {
          onChangeNumber(true);
        }}
      >
        {t("verifyNumber.changeNumber")}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 44,
          direction: t("css.side"),
        }}
      >
        <span style={{ ...h7Regular, color: "#15222F", opacity: 0.4 }}>
          {t("verifyNumber.smsCode")}
        </span>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {Object.keys(inputRefs).map((key, index) => (
            <input
              key={index}
              maxLength={1}
              name={key}
              onChange={(e) => handleChange(e, key)}
              style={inputDigitStyle}
              ref={inputRefs[key]}
              type="number"
              value={digits[key]}
            />
          ))}
        </div>
      </div>
      <button
        style={{
          width: "100%",
          height: "56px",
          background: !isValid ? "lightGrey" : "#80BB34",
          color: "#ffffff",
          borderRadius: 16,
          border: "none",
          ...pointer,
          ...h5Medium,
          marginBottom: 32,
        }}
        onClick={() => {
          if (isValid) {
            onVerify(digits);
          }
        }}
      >
        {t("verifyNumber.verify")}
      </button>

      <div
        style={{
          textAlign: "center",
          ...h6Medium,
          color: "#80BB34",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GroupIcon style={{ marginRight: 8 }} />
        <span style={{ ...pointer }} onClick={onResend}>{t("verifyNumber.resendCode")}</span>
      </div>
    </div>
  );
};

export default VerifyNumber;

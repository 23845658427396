import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n";
import { Suspense } from "react";
import Loader from "./components/common/loader/loader.component";

ReactDOM.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "60vh",
          }}
        >
          <Loader color={"#B73232"} size={70} />
        </div>
      }
    >
      <App />
    </Suspense>
  </I18nextProvider>,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

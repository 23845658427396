import React, { useContext } from 'react';
import { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowDownIcon } from "../../../assets/arrow.svg";
import { sortDropdownOptions } from './sort-dropdown.helper';
import { FilterContext } from "../../../context/filterContext";

import './sort-dropdown.css';

const SortDropdown = ({ onSort }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { searchState } = useContext(FilterContext);

    const { sort } = searchState;

    const currentOption = sortDropdownOptions.find(option => {
        for (const key in sort) {
            if (sort[key] !== option.sort[key]) {
                return false;
            }
        }
        return true;
    });

    const [optionSelected, setOptionSelected] = useState(currentOption);
    const dropdownRef = useRef(null);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        window.addEventListener("click", handleClickOutside);

        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const onOptionSelected = (option) => {
        if (option.label === optionSelected?.label) return;

        setOptionSelected(option);
        onSort(option.sort);
    }

    return <div className='sort-dropdown-container'>
        <span className="h6-medium" style={{ opacity: 0.8 }}>Sort by:</span>
        <div
            className="sort-dropdown"
            onClick={(e) => {
                setIsOpen(!isOpen);
            }}
            ref={dropdownRef}
        >
            <span className="h5-medium sort-dropdown-label">
                {optionSelected?.label}
            </span>
            <ArrowDownIcon />

            {isOpen ? <div className='sort-dropdown-options-container'>
                {[optionSelected, ...sortDropdownOptions.filter(option => option.label !== optionSelected?.label)].map(option => (
                    <div className='sort-dropdown-option h5-medium sort-dropdown-label'
                        onClick={() => onOptionSelected(option)}>
                        {option?.label}
                    </div>
                ))}
            </div> : null}
        </div>
    </div>
};

export default SortDropdown;
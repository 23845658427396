import React, { useState } from "react";

const initialState = {
  color: "#15222F",
  showCategories: true,
  showSearch: true,
  showMainHeader: true,
  toggleFixed: true,
};

const HeaderContext = React.createContext(initialState);

const HeaderContextProvider = ({ children }) => {
  const [headerState, setHeaderState] = useState(initialState);

  return (
    <HeaderContext.Provider value={{ headerState, setHeaderState }}>
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderContextProvider };

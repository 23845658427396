/* eslint-disable no-sequences */
import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { FETCH_LANGUAGE } from "../../queries/user.queries";
import { GET_ACTIVE_USER_LEAN } from '../../queries/auth.queries';
import { AccountContext } from "../../context/account.context";

const AppBootstrap = () => {
  const { accountState, setAccountState } = useContext(AccountContext);
  const { data: userData } = useQuery(GET_ACTIVE_USER_LEAN);
  const getData = () => {
    fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((sharedData) => {
        setAccountState({ ...accountState, sharedData })
      });
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const engatiWrapper = window.document.getElementById("engtWrapper");
    console.log("engatiWrapper ", engatiWrapper)
    if (!userData) {

    }
  }, [userData])

  // function reloadChatWidget(attributes) {
  //   var a = {
  //     bot_key: "f106d52873bf4513",
  //     welcome_msg: true,
  //     branding_key: "default",
  //     server: "https://app.engati.com",
  //     e: "p",
  //     user_attributes: attributes,
  //     override_pop_up: true,
  //   };
  //   document.getElementById("engt") && document.getElementById("engt").remove();
  //   var c = document.head || document.getElementsByTagName("head")[0],
  //     n = document.createElement("script");
  //   n.async = !0;
  //   n.defer = !0;
  //   n.type = "text/javascript";
  //   n.src =
  //     "https://app.engati.com/static/js/chat_widget.js?config=" +
  //     JSON.stringify(a) +
  //     "&ts=" +
  //     new Date().getTime();
  //   c.appendChild(n);
  // }

  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  // const func = function (url, engatiConfig) {
  //   let c = document.head || document.getElementsByTagName("head")[0];
  //   const exsist = document.getElementById("engati_script");
  //   if (exsist != null) {
  //     c.removeChild(exsist);
  //   }
  //   let n = document.createElement("script");
  //   n.id = "engati_script";
  //   n.async = !0;
  //   n.defer = !0;
  //   n.type = "text/javascript";
  //   n.src =
  //     url + "/static/js/chat_widget.js?config=" + JSON.stringify(engatiConfig);
  //   c.appendChild(n);
  // };
  // React.useEffect(() => {
  //   if (!token) return;
  //   reloadChatWidget([
  //     {
  //       name: "user.token",
  //       value: token,
  //     },
  //     {
  //       name: "user.language",
  //       value: lang,
  //     },
  //   ]);
  // }, [lang, token]);

  const reloadChatWidget = (attributes) => {

    let a = {
      bot_key: "f106d52873bf4513",
      welcome_msg: true,
      branding_key: "default",
      server: "https://app.engati.com",
      e: "p",
      user_attributes: attributes,
      override_pop_up: true
    };

    if (document.getElementById('engt')) {
      // console.log(document.getElementById('engt'))
      // ReactDOM.findDOMNode(document.getElementById('engt')).remove();
      // document.getElementById('engt').remove();

    }

    let c = document.head || document.getElementsByTagName("head")[0];
    let n = document.createElement("script");
    n.async = !0; n.defer = !0; n.type = "text/javascript"; n.src = "https://app.engati.com/static/js/chat_widget.js?config=" + JSON.stringify(a) + '&ts=' + new Date().getTime(); c.appendChild(n);
  }

  React.useEffect(() => {
    if (!token) return;
    // reloadChatWidget([{
    //   name: "user.token",
    //   value: token
    // }, {
    //   name: "user.language",
    //   value: lang
    // }]);
  }, [token, lang])

  const { data } = useQuery(FETCH_LANGUAGE);
  document.title = accountState?.sharedData?.TITLE ?? "";

  // React.useEffect(() => {
  //   if (!data) return;
  //   if (data?.activeCustomer?.languageCode) {
  //     debugger;
  //     localStorage.setItem("lang", data?.activeCustomer?.languageCode);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  return <></>;
};

export default AppBootstrap;

import { gql } from "@apollo/client";

export const GET_TIME_WINDOWS = gql`
  query {
    timeWindows {
      id
      description
      startsAt
      endsAt
    }
  }
`;

import React from "react";
import AddressProcess from "../address-process/address-process.component";
import DeliveryProcess from "../delivery-process/delivery-process.component";
// import PaymentProcess from "../payment-process/payment-process.component";
import OrderProcess from "../order-process/order-process.component";
import { makeStyles } from "@material-ui/core";
import LoginProcess from "../login-process/login-process.component";
import PersonalInfo from "../personal-info/personal-info.component";

const useStyles = makeStyles({
  orderProcess: {
    "@media (min-width:940px)": {
      display: "none",
    },
  },
});

const CheckoutProcess = ({
  isActiveCustomer,
  setReadyForUserDetails,
  setReadyForLogin,
  setReadyForAddress,
  setReadyForPayment,
  setReadyForDelivery,
  processes,
  onEditAddress,
  onEditPayment,
  onEditDeliveryTime,
  onEditUserDetails,
  userData
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {!isActiveCustomer ? (
        <LoginProcess setProcesses={setReadyForLogin} processes={processes} />
      ) : (
        ""
      )}
      {!userData?.activeCustomer?.firstName || !userData?.activeCustomer?.phoneNumber ?
        <PersonalInfo
          processes={processes}
          setProcesses={setReadyForUserDetails}
          onEditUserDetails={onEditUserDetails}
        /> : null}
      <AddressProcess
        setProcesses={setReadyForAddress}
        processes={processes}
        onEditAddress={onEditAddress}
      />
      {/* Temp removeed until payments iFrame implementation */}
      {/* <PaymentProcess
        setProcesses={setReadyForPayment}
        processes={processes}
        onEditPayment={onEditPayment}
      /> */}
      <DeliveryProcess
        setProcesses={setReadyForPayment}
        processes={processes}
        onEditDeliveryTime={onEditPayment}
      />
      <div className={classes.orderProcess}>
        <OrderProcess
          setProcesses={setReadyForDelivery}
          processes={processes}
        />
      </div>
    </React.Fragment>
  );
};

export default CheckoutProcess;

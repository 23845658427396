import { gql } from "@apollo/client";

export const SET_ORDER_TIME_WINDOW = gql`
  mutation ($timeWindowId: ID!) {
    setOrderTimeWindow(timeWindowId: $timeWindowId) {
      ... on Order {
        timeWindow {
          description
        }
        deliveryTime
      }
      __typename
    }
  }
`;

import React, { useContext, useEffect } from "react";

import CatalogItemsContainer from "../catalog-items-container/catalog-items-container";
import CatalogFilters from "../catalog-filters/catalog-filters";
import "./catalog-main.css";
import { FilterContext } from "../../../context/filterContext";
import CatalogFiltersTags from "../catalog-filters-tags/catalog-filters-tags.component";
import { ReactComponent as VectorIcon } from "../../../assets/Vector.svg";
import { Drawer } from "@material-ui/core";
import CatalogFiltersResponsive from "../catalog-filters-responsive.js/catalog-filters-responsive.component";
import NoResultsPage from "../../common/no -results-page/no-results-page.component";
// import PopularCategories from "../../common/populer-categories/populer-categories.component";
import { useTranslation } from "react-i18next";
import Loader from "../../common/loader/loader.component";
import { useLocation } from "react-router-dom";

function CatalogMain({ items, toggleDrawer, state, loading }) {
  const { t } = useTranslation();
  const location = useLocation();

  const { searchState, setSearchState } = useContext(FilterContext);

  const {
    collectionId,
    facetValueIds,
    facetFaluesPerCollectionInit,
    term,
  } = searchState;

  if (collectionId && facetFaluesPerCollectionInit) {
    setSearchState({
      ...searchState,
      facetFaluesPerCollectionInit: false,
      previousPage: location?.pathname
    });
  }

  const onSubmitForm = (filterChanges) => {
    if (filterChanges) {
      const { term, collectionId, facetValueIds } = filterChanges;
      setSearchState({
        ...searchState,
        term,
        collectionId,
        facetValueIds: facetValueIds?.length ? [...facetValueIds] : [],
        previousPage: location?.pathname
      });
    }
  };

  const onSeeAllClicked = (collection) => {
    setSearchState({
      ...searchState,
      term: "",
      facetValueIds: [],
      collectionId: collection,
      facetFaluesPerCollectionInit: true,
      previousPage: location?.pathname
    });
  };

  const updateFilters = (e) => {
    setSearchState({
      ...searchState,
      facetValueIds: [],
      collectionId: e,
      term: "",
      facetFaluesPerCollectionInit: true,
      previousPage: location?.pathname
    });
  };

  const updateSearchFilters = (e) => {
    setSearchState({
      isInit: true,
      facetFaluesPerCollectionInit: true,
      term: "",
      facetValuesDefaultFilters: [],
      facetFaluesDefaultPerCollection: [],
      facetValueIdsPerCollection: [],
      facetValueIds: [],
      collectionId: null,
      previousPage: location?.pathname
    });
  };

  let numberOfFiltersApplied = facetValueIds.length;

  if (term !== "") {
    numberOfFiltersApplied += 1;
  }

  if (collectionId) {
    numberOfFiltersApplied += 1;
  }

  const noResultsDescription = (
    <div>
      <span>
        {t("catalog.NoMatchingResultsFor")} {term}
        {"."}
      </span>
      <br />
      <span>{t("catalog.Try changing keywords")}</span>
    </div>
  );

  if (
    items?.length === 0 &&
    term !== ""
  ) {
    return (
      <NoResultsPage
        title={t("catalog.noMatchingProducts")}
        descriprion={noResultsDescription}
        goBack={updateSearchFilters}
      >
        {/* <PopularCategories updateFilters={updateFilters} /> */}
      </NoResultsPage>
    );
  }


  return (
    <React.Fragment>
      <div className="catalog-main__container">
        <div className="catalog-main__filters">
          <CatalogFilters isResponsive={false} />
        </div>
        {loading ?
          <div className="catalog-main__items">
            <Loader color={"#B73232"} size={100} />
          </div>
          : <div className="catalog-main__items">
            <CatalogItemsContainer
              collectionId={collectionId}
              facetValueIds={facetValueIds}
              items={items}
              onSeeAllClicked={onSeeAllClicked}
              term={term}
            />
          </div>}
      </div>
      <div className="catalog-main__filters--responsive">
        <div className="catalog-main__filters--responsive-header">
          <button
            className="catalog-main__filters--responsive-header__filter-buttons"
            style={{
              background: "#80bb34",
              color: "white",
              width: 102,
              height: 32,
            }}
            onClick={toggleDrawer("bottom", true)}
          >
            <VectorIcon />
            <span style={{ width: 37, height: 16, margin: "0px 8px" }}>
              {t("catalog.Filters")}
            </span>
            <span>
              {numberOfFiltersApplied > 0 ? numberOfFiltersApplied : ""}
            </span>
          </button>
        </div>
        <div className="catalog-main__filters--responsive-header__separator-line"></div>
        <CatalogItemsContainer
          collectionId={collectionId}
          facetValueIds={facetValueIds}
          items={items}
          onSeeAllClicked={onSeeAllClicked}
        />
      </div>
      <Drawer
        anchor="bottom"
        open={state["bottom"]}
        onClose={toggleDrawer("bottom", false)}
        onOpen={toggleDrawer("bottom", true)}
      >
        <CatalogFiltersResponsive
          toggleDrawer={toggleDrawer}
          onSubmitForm={onSubmitForm}
        />
      </Drawer>
    </React.Fragment>
  );
}

export default CatalogMain;

import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { h3 } from "../../../shared/constants";
import { ReactComponent as CreditCardIcon } from "../../../assets/credit-card.svg";
import CheckoutTotal from "../checkout-total/checkout-total.component";
import { useTranslation } from "react-i18next";

const OrderProcess = ({ setProcesses, processes }) => {
  const { stage3DeliverTo, stage4Payment } = processes;
  const { t } = useTranslation();

  const [closeAccordion, setCloseAccordion] = useState(false);

  useEffect(() => {
    setCloseAccordion(stage4Payment);
  }, [stage4Payment]);

  return (
    <Accordion
      style={{
        borderRadius: "16px",
        padding: 30,
        boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
        marginBottom: 24,
        borderBottom: "none",
      }}
      expanded={closeAccordion}
      disabled={!stage3DeliverTo}
    >
      <AccordionSummary
        // expandIcon={"edit"}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          setCloseAccordion(!closeAccordion);
        }}
      >
        <Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              opacity: stage3DeliverTo ? 1 : 0.64,
            }}
          >
            <CreditCardIcon style={{ marginRight: 16 }} />
            <span style={{ ...h3 }}>{t("checkout.Order")}</span>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <CheckoutTotal processes={processes} />
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderProcess;

import React from "react";
import { Snackbar } from "@material-ui/core";
import { useContext } from "react";

import { ErrorHandlingContext } from "../../../context/error-handling.context";
import Alert from "../alert/alert.component";

const WithErrorHandling = (Component) =>
  function Comp(props) {
    // for error handling
    const { errorHandlingState, setErrorHandlingState } =
      useContext(ErrorHandlingContext);

    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState("");

    React.useEffect(() => {
      if (!errorHandlingState?.showError) return;
      openAlert(errorHandlingState?.data);
    }, [errorHandlingState]);

    const openAlert = (error) => {
      setError(error);
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setErrorHandlingState({
        showError: false,
        data: "",
      });
      setOpen(false);
    };
    return (
      <>
        <Component {...props} />
        <Snackbar
          open={open}
          autoHideDuration={2500}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>{error}</div>
          </Alert>
        </Snackbar>
      </>
    );
  };

export default WithErrorHandling;

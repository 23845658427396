import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./footer.css";
import { AccountContext } from "../../context/account.context";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as HeLogo } from "../../assets/logo_he.svg";
import { ReactComponent as EnLogo } from "../../assets/logo_en.svg";

function Footer() {
  const { t } = useTranslation();
  const { accountState } = useContext(AccountContext);
  const dir = t("css.side");
  return (
    <footer className="footer dark" style={{ direction: dir }}>
      <div className={`footer__logo ${t("css.footerLogoDesign")}`}>
        {t("graphql.languageCode") === "en" ? (
          <EnLogo width={80} height={72} />
        ) : (
          <HeLogo width={80} height={72} />
        )}
      </div>
      <div className="footer__middle-divs">
        <div className="footer__middle-divs__inner-div">
          <span className="footer__middle-divs__title h5-medium">
            {t("footer.About")}
          </span>
          <div className="footer__middle-divs__links">
            <span className="footer__middle-divs__link h6-regular">
              {t("common.ourSuppliers")}
            </span>
          </div>
        </div>
        <div className="footer__middle-divs__inner-div">
        </div>
        <div className="footer__middle-divs__inner-div">
        </div>

        <div className={`footer__last-div ${t("css.footerLastDivDesign")}`}>
          <span className="footer__middle-divs__title h5-medium">
            {t("footer.Need Help")}
          </span>
          <div
            className="h6-regular footer__customer-care-link"
            style={{ paddingRight: dir === "ltr" ? 16 : 0 }}
          >
            <span>{t("footer.Visit customer care")}</span>
            <div>
              <ArrowRight />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

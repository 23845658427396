import React from "react";
import { useTranslation } from "react-i18next";

import "./breadcrumbs.component.css";

const Breadcrumbs = ({ onGoBack, prevPage, children }) => {
  const { t } = useTranslation();
  const goBack = () => {
    onGoBack();
  };
  return (
    <div className="breadcrumbs-container">
      <span
        onClick={() => {
          goBack();
        }}
        className="h6-medium pointer breadcrumbs-title"
      >
        {prevPage}
      </span>
      {children}
    </div>
  );
};

export default Breadcrumbs;

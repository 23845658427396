import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Backdrop, Fade, Modal } from "@material-ui/core";

import Loader from "../../common/loader/loader.component";
import {
    CHANGE_EMAIL_ADDRESS,
    GET_ACTIVE_USER,
    UPDATE_PASSWORD,
} from "../../../queries/auth.queries";
import {
    h3,
    h6Medium,
    h6Regular,
    pointer,
} from "../../../shared/constants";
import FormInput from "../../common/form-input/form-input.component";
import ChangeEmailPassword from '../../common/change-email-password/change-email-password';

const useStyles = makeStyles({
    security: {
        display: "flex",
        flexDirection: "column",
        background: "#FFFFFF",
        borderRadius: 16,
        width: "auto",
        height: 330,
        marginBottom: 24,
        padding: 40,
    },
    innerTitles: {
        ...h3,
        marginBottom: 40,
    },
    editParams: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 500,
        ...h6Regular,

        "@media (max-width:940px)": {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            width: "100%",
        },
    },
    formInput: {
        width: "70%",

        "@media (max-width:940px)": {
            width: "auto",
        },
    },
    change: {
        ...h6Medium,
        ...pointer,
        color: "#80BB34",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const AccountSettingsSecurity = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [currentPass, setCurrentPass] = useState("");
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [message, setMessage] = useState("");
    const [isSnackOpen, setIsSnackOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEmailType, setIsEmailType] = useState(false);

    const [activeUser, setActiveUser] = useState({
        emailAddress: "",
        oldPassword: "",
    });

    const { loading, error, data } = useQuery(GET_ACTIVE_USER);

    useEffect(() => {
        if (data && data.activeCustomer) {
            const { emailAddress } = data.activeCustomer;
            setActiveUser({
                emailAddress,
                oldPassword: "123456"
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    let [updatePass, { loading: updatePassLoader }] = useMutation(
        UPDATE_PASSWORD,
        {
            refetchQueries: [
                { query: GET_ACTIVE_USER }, // Query name
            ],
            awaitRefetchQueries: true,
        }
    );

    let [changeEmailAddress, { loading: changeEmailAddressLoader }] = useMutation(
        CHANGE_EMAIL_ADDRESS,
        {
            refetchQueries: [
                { query: GET_ACTIVE_USER }, // Query name
            ],
        }
    );

    if (loading) return <Loader color={"#B73232"} size={50} />;

    if (error) {
        console.log(error);
    }

    const onChangeEmail = async () => {
        setIsEmailType(true);
        setIsModalOpen(true);
    };

    const onUpdatePass = async () => {
        setIsEmailType(false);
        setIsModalOpen(true);
    };

    const onEmailChanged = async (updatedData) => {
        const { email, password } = updatedData;

        if (!email?.length || !password?.length) return;
        try {
            const data = await changeEmailAddress({
                variables: {
                    password: password,
                    emailAddress: email,
                }
            });

            if (
                data &&
                data?.data?.requestUpdateCustomerEmailAddress?.__typename === "Success"
            ) {
                setPasswordChanged(true);
                setMessage(t("auth.emailSent"));
                setIsSnackOpen(true);
                setIsModalOpen(false);
                return;
            } else {
                setPasswordChanged(false);
                setMessage(data?.data?.requestUpdateCustomerEmailAddress?.message);
                setIsSnackOpen(true);
            }
        } catch (err) { }
    };

    const onUpdatePassword = async (updatedData) => {
        const { oldPassword, newPassword } = updatedData;

        if (!oldPassword?.length || !newPassword?.length) return;
        try {
            const data = await updatePass({
                variables: {
                    old: oldPassword,
                    new: newPassword,
                }
            });

            if (
                data &&
                data?.data?.updateCustomerPassword?.__typename === "Success"
            ) {
                setPasswordChanged(true);
                setMessage(t("auth.passwordChanged"));
                setIsSnackOpen(true);
                setIsModalOpen(false);
                return;
            }
            if (
                data &&
                data?.data?.updateCustomerPassword?.__typename ===
                "InvalidCredentialsError"
            ) {
                setPasswordChanged(false);
                setMessage(data?.data?.updateCustomerPassword?.message);
                setIsSnackOpen(true);
            }

            if (data && data?.data?.resetPassword?.__typename === "ErrorResult") {
                setPasswordChanged(false);
                setMessage(data?.data?.updateCustomerPassword?.message);
                setIsSnackOpen(true);
            }
        } catch (err) { }
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (!data || !data.activeCustomer) return;

    const onCloseSnack = (bool) => {
        setIsSnackOpen(bool);
    };

    const onSubmit = (updatedData) => {
        if (isEmailType) {
            onEmailChanged(updatedData);
        }
        else {
            onUpdatePassword(updatedData);
        }
    };

    return <div className={classes.security}>
        <span className={classes.innerTitles}>{t("account.Security")}</span>
        <div className={classes.editParams}>
            <div className={classes.formInput}>
                <FormInput
                    name="emailAddress"
                    type="string"
                    value={activeUser?.emailAddress}
                    label={t("auth.Email")}
                    margin={"20px 0px"}
                    disabled
                />
            </div>
            <span
                className={classes.change}
                onClick={async () => {
                    await onChangeEmail();
                }}>
                {t("account.Change")}
            </span>
        </div>
        <div className={classes.editParams}>
            <div className={classes.formInput}>
                <FormInput
                    name="oldPassword"
                    type="password"
                    value={activeUser?.oldPassword}
                    label={t("auth.oldPassword")}
                    disabled
                />
            </div>
            <span
                className={classes.change}
                onClick={async () => {
                    await onUpdatePass();
                }}
            >
                {t("account.Change")}
            </span>
        </div>
        <Snackbar
            open={isSnackOpen}
            autoHideDuration={1000}
            onClose={() => onCloseSnack(false)}
            anchorOrigin={{
                vertical: "top",
                horizontal: `${t("css.snackBarPosition")}`,
            }}
        >
            <Alert
                onClose={() => onCloseSnack(false)}
                severity={passwordChanged ? "success" : "error"}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{message}</span>
                </div>
            </Alert>
        </Snackbar>
        <Modal
            className={classes.modal}
            open={isModalOpen}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isModalOpen}>
                <ChangeEmailPassword
                    isEmailType={isEmailType}
                    handleCloseModal={handleCloseModal}
                    onSubmit={onSubmit}
                    updatePassLoader={updatePassLoader}
                    changeEmailAddressLoader={changeEmailAddressLoader} />
            </Fade>
        </Modal>
    </div>
};

export default AccountSettingsSecurity;
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FilterContext } from "../../../context/filterContext";
import { ReactComponent as BreadCrumbsArrowLeft } from "../../../assets/leftArrow.svg";
import { ReactComponent as BreadCrumbsArrow } from "../../../assets/rightArrow.svg";

const backToHomeStyle = {
    color: "#80bb34",
    display: "flex",
    alignItems: "center",
    gap: 8,
    width: 150,
    padding: "24px 0px 56px 0px"
};

const SupplierPageBreadcrumbs = ({ merchant }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const { searchState, setSearchState } = useContext(FilterContext);

    console.log('searchState ', searchState);

    const { previousPage, collectionId, facetValueIds } = searchState;

    const onBackToHome = () => history.push(`/`);


    const activeNavigationClassName = "h6-medium pointer breadcrumbs-title";

    if (previousPage === "") {
        return <div style={backToHomeStyle} className="h6-medium pointer" onClick={onBackToHome}>
            <BreadCrumbsArrowLeft style={{ width: 16, height: 16 }} />
            {t('catalog.backToHome')}
        </div>
    };

    const onNavigationItemClicked = (typename = null) => {
        if (!typename) {
            setSearchState({
                isInit: true,
                facetFaluesPerCollectionInit: true,
                facetValuesDefaultFilters: [],
                facetFaluesDefaultPerCollection: [],
                facetValueIdsPerCollection: [],
                facetValueIds: [],
                collectionId: null,
                term: ""
            });
            history.push(previousPage);
        }

        if (typename === "facet") {
            setSearchState({
                ...searchState,
                term: "",
                collectionId,
                facetValueIds
            });
            history.push("/catalog");
        }

        if (typename === "collection") {
            setSearchState({
                ...searchState,
                facetValueIds: [],
                collectionId,
                term: ""
            });
            history.push("/catalog");
        }

        if (typename === "itemName") {
            history.push(previousPage);
            return;
        }
    };

    const currentCollectionFilter = <div
        onClick={() => onNavigationItemClicked("collection")}
        style={{ display: "flex", gap: 16, alignItems: "center" }}
    >
        <BreadCrumbsArrow />
        <span className={activeNavigationClassName}>{collectionId?.name}</span>
    </div>;

    const currentFacetFilter = <div
        onClick={() => onNavigationItemClicked("facet")}
        style={{ display: "flex", gap: 16, alignItems: "center" }}
    >
        <BreadCrumbsArrow />
        <span className={activeNavigationClassName}>{facetValueIds[0]?.name}</span>
    </div>;

    const itemName = <div
        onClick={() => onNavigationItemClicked("itemName")}
        style={{ display: "flex", gap: 16, alignItems: "center" }}
    >
        <BreadCrumbsArrow />
        <span className={activeNavigationClassName}>{searchState?.itemName}</span>
    </div>

    const ourSuppliers = <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
        <BreadCrumbsArrow />
        <span className="h6-regular">{merchant?.name}</span>
    </div>

    const breadcrumbs = <div style={{ display: "flex", gap: 16, alignItems: "center", padding: "24px 0px 56px 0px" }}>
        <span
            onClick={() => onNavigationItemClicked(null)}
            className="h6-medium pointer breadcrumbs-title"
        >
            {searchState?.previousPage !== "/home#our-suppliers" ? t("header.categories") : t("common.ourSuppliers")}
        </span>
        {collectionId ? currentCollectionFilter : null}
        {facetValueIds[0] ? currentFacetFilter : null}
        {searchState?.itemName ? itemName : null}
        {ourSuppliers}
    </div>;

    return breadcrumbs;
};

export default SupplierPageBreadcrumbs;
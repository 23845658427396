export const loginErrorTypes = {
  InvalidCredentialsError: "The provided credentials are invalid",
  NativeAuthStrategyError: "NativeAuthStrategyError",
  ErrorResult: "ErrorResult",
  NotVerifiedError: "Please verify this email address before logging in",
};

export const registerErrorTypes = {
  MissingPasswordError: "MissingPasswordError",
  NativeAuthStrategyError: "NativeAuthStrategyError",
  ErrorResult: "ErrorResult",
};

export const isErrorLogin = (errrMsg) => {
  return loginErrorTypes.hasOwnProperty(errrMsg);
};

export const isErrorRegister = (errrMsg) => {
  return registerErrorTypes.hasOwnProperty(errrMsg);
};

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { RESET_PASSWORD } from "../../../queries/auth.queries";
import { h4Medium, h5Medium, pointer } from "../../../shared/constants";
import FormInput from "../../common/form-input/form-input.component";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [password, setPassword] = useState({
    newPassword: "",
  });
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [message, setMessage] = useState("");

  const onCloseSnack = (bool) => {
    setIsSnackOpen(bool);
  };

  const location = useLocation();
  const [, token] = location?.search?.split("=");

  const [reset] = useMutation(RESET_PASSWORD, {
    variables: {
      token,
      password: password.newPassword,
    },
  });

  const handleChange = (e) => {
    const { value, name } = e.target;

    setPassword({ [name]: value });
  };

  const onResetPassword = async () => {
    const data = await reset();

    if (data && data?.data?.resetPassword?.__typename === "CurrentUser") {
      await setPasswordChanged(true);
      await setMessage(t("auth.passwordChanged"));
      await setIsSnackOpen(true);
      setTimeout(() => {
        history.push("/home");
      }, 2000);
      return;
    }
    if (
      data &&
      data?.data?.resetPassword?.__typename === "PasswordResetTokenInvalidError"
    ) {
      await setPasswordChanged(false);
      await setMessage(data?.data?.resetPassword?.message);
      await setIsSnackOpen(true);
    }

    if (data && data?.data?.resetPassword?.__typename === "ErrorResult") {
      await setPasswordChanged(false);
      await setMessage(data?.data?.resetPassword?.message);
      await setIsSnackOpen(true);
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 55,
        }}
      >
        <span style={{ ...h4Medium }}>{t("auth.setNewPassword")}</span>
        <div
          style={{
            background: "#FFF",
            width: 350,
            padding: 15,
            borderRadius: 16,
            marginTop: 15,
          }}
        >
          <FormInput
            name="newPassword"
            type="string"
            value={password.newPassword}
            handleChange={handleChange}
            label={t("auth.newPassword")}
            border="1px solid rgba(0, 0, 0, 0.1)"
            maxWidth={"100%"}
            margin={0}
            style={{ padding: "10px 0" }}
          />
          <button
            type="submit"
            style={{
              width: "100%",
              border: "none",
              borderRadius: 16,
              height: 36,
              textAlign: "center",
              background: "#80BB34",
              ...h5Medium,
              color: "#FFFFFF",
              ...pointer,
            }}
            onClick={() => {
              onResetPassword();
            }}
          >
            {t("auth.reset")}
          </button>
        </div>
      </div>
      <Snackbar
        open={isSnackOpen}
        autoHideDuration={1000}
        onClose={() => onCloseSnack(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: `${t("css.snackBarPosition")}`,
        }}
      >
        <Alert
          onClose={() => onCloseSnack(false)}
          severity={passwordChanged ? "success" : "error"}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{message}</span>
          </div>
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default ResetPassword;

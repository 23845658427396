import React from "react";

import "./item-page-item-details.component.css";

const ItemPageItemDetails = ({ data }) => {
  return (
    <div className="IPID">
      <div className="IPID--list">
        <span
          className="h5-regular"
          dangerouslySetInnerHTML={{ __html: data?.product?.description }}
        ></span>
      </div>
      <div className="IPID--tags">
        {data?.product?.collections?.map((col) => (
          <div key={col.id} className="IPID--tags--tag h6-regular">
            {col.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemPageItemDetails;

import { useQuery } from "@apollo/client";
import React from "react";
import Badge from "@material-ui/core/Badge";
import { useTranslation } from "react-i18next";
import { GET_TOTAL_LINES } from "../../../queries/cart.queries";
import BasketIcon from "../../common/svg/basket/basket";

import "./cart-indicator.component.css";

const CartIndicator = ({ onClick, iconColor }) => {
  const { data } = useQuery(GET_TOTAL_LINES);

  let total = 0;
  if (data && data.activeOrder) {
    total = data.activeOrder.lines.length;
  }
  const { t, i18n } = useTranslation();
  const iconPlacement =
    i18n.language && i18n.language === "he" ? "left" : "right";

  return (
    <>
      <div className="cart-indicator-container">
        <Badge
          overlap="rectangular"
          classes={{ root: "pointer", colorPrimary: "pink" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: iconPlacement,
          }}
          badgeContent={total}
          color="primary"
          onClick={onClick}
        >
          <BasketIcon stroke={iconColor} />
        </Badge>
      </div>
    </>
  );
};

export default CartIndicator;

import React, { useState } from "react";
import DeliveryTruckIcon from "../../common/svg/delivery-truck-icon/delivery-truck-icon";
import { useTranslation } from "react-i18next";
import { h6Regular, h7Regular } from "../../../shared/constants";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";

const singleLineEllipsis = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: 120,
  ...h6Regular,
};

const HeaderDeliverTo = ({ userData, color, handleOpenAddressModal }) => {
  const address = userData?.activeCustomer?.addresses?.find(
    (address) => address?.defaultShippingAddress
  );

  const { t } = useTranslation();
  const truckIconStyle = t("header.truckIconStyle");

  if (!address) {
    return null;
  }

  return (
    <div className="HM__delivery-address">
      <Tooltip title={address?.streetLine1} placement="top">
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handleOpenAddressModal}
        >
          <i className={truckIconStyle}>
            <DeliveryTruckIcon stroke={color} />
          </i>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 8,
            }}
          >
            <span style={{ ...h7Regular, opacity: 0.7, color }}>
              {t("account.deliveryTo")}
            </span>
            <span style={{ ...singleLineEllipsis, color }}>
              {address?.streetLine1}
            </span>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default HeaderDeliverTo;

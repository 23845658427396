import React, { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Snackbar } from "@material-ui/core";

import AddPhone from "../add-phone/add-phone.component";
import VerifyNumber from "./verifyNumber.component";
import { SEND_VERIFICATION_CODE_TO_CUSTOMER, VERIFY_CUSTOMER_HPHONE_NUMBER } from '../../../mutations/phone-validation';
import Loader from "../../common/loader/loader.component";
import { ErrorHandlingContext } from "../../../context/error-handling.context";
import Alert from "../../../shared/components/alert/alert.component";

const INITIAL_ALERT_DATA = {
  showAlert: false,
  message: ''
}

const VerifyNumberContainer = ({ handleClose, phoneNumber, setPhoneValue, setOpen, onVerifyFunc }) => {
  const [addPhoneScreen, setAddPhoneScreen] = useState(false);
  const [successAlert, setSuccessAlert] = useState(INITIAL_ALERT_DATA);
  const [verificationCode, setVerificationCode] = useState(null);
  const { setErrorHandlingState } = useContext(ErrorHandlingContext);

  let [sendVerificationCodeToCustomer, { loading: verificationCodeLoader, error: verificationCodeError }] = useMutation(
    SEND_VERIFICATION_CODE_TO_CUSTOMER,
    {
      variables: {
        phoneNumber,
      },
      onCompleted: (res) => {
        const response = res?.sendVerificationCodeToCustomer;

        if (response?.errorCode) {
          setErrorHandlingState({
            showError: true,
            data: response?.message
          });
          return;
        }
        setSuccessAlert({
          showAlert: true,
          message: 'Verification code sent!'
        });
      }
    }
  );


  let [verifyCustomerPhoneNumber, { loading: verifyCustomerPhoneNumberLoader, error: verifyCustomerPhoneNumberError }] = useMutation(
    VERIFY_CUSTOMER_HPHONE_NUMBER,
    {
      variables: {
        phoneNumber,
        verificationCode
      },
      onCompleted: (res) => {
        const response = res?.verifyCustomerPhoneNumber;

        if (response?.errorCode) {
          setErrorHandlingState({
            showError: true,
            data: response?.message
          });
          return;
        }
        setSuccessAlert({
          showAlert: true,
          message: 'Verified successfully!'
        });
      }
    }
  );


  const onVerify = async (digits) => {
    if (onVerifyFunc) {
      onVerifyFunc(digits);
      return;
    }

    const code = Object.values(digits).join('');

    if (!code) return;
    setVerificationCode(code);
  };

  useEffect(async () => {
    if (!phoneNumber) return;

    try {
      await sendVerificationCodeToCustomer();
    } catch (err) {
      setErrorHandlingState({
        showError: true,
        data: err?.message
      });
    }
  }, []);

  useEffect(async () => {
    if (!verificationCode) return;

    try {
      await verifyCustomerPhoneNumber();
    } catch (err) {
      setErrorHandlingState({
        showError: true,
        data: err?.message
      });
    }
  }, [verificationCode]);

  useEffect(() => {
    if (!verificationCodeError) return;

    setErrorHandlingState({
      showError: true,
      data: verificationCodeError?.message
    });
  }, [verificationCodeError]);

  const onChangeNumber = (shouldDisplay) => {
    setAddPhoneScreen(shouldDisplay);
    if (!shouldDisplay) {
      sendVerificationCodeToCustomer();
    }
  };

  const onResend = async () => {
    try {
      await sendVerificationCodeToCustomer();
    } catch (err) {
      setErrorHandlingState({
        showError: true,
        data: verificationCodeError?.message
      });
    }
  };

  if (verificationCodeLoader || verifyCustomerPhoneNumberLoader) {
    return <Loader color={"#B73232"} />
  }

  const handleSuccesEditClose = () => {
    setSuccessAlert(INITIAL_ALERT_DATA);
  };

  return (
    <React.Fragment>
      {addPhoneScreen ? (
        <AddPhone
          onChangeNumber={onChangeNumber}
          handleClose={handleClose}
          phoneValue={phoneNumber}
          setPhoneValue={setPhoneValue}
        />
      ) : (
        <VerifyNumber
          onChangeNumber={onChangeNumber}
          handleClose={handleClose}
          onVerify={onVerify}
          phoneNumber={phoneNumber}
          onResend={onResend}
        />
      )}
      <Snackbar
        open={successAlert.showAlert}
        autoHideDuration={2500}
        onClose={handleSuccesEditClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSuccesEditClose} severity="success" sx={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>{successAlert.message}</div>
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default VerifyNumberContainer;

import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import { h5Medium, h6Medium, pointer, h6Regular, getShortMonthAndDay } from "../../../shared/constants";
import { h3 } from "../../../shared/constants";
import { ReactComponent as ScheduleIcon } from "../../../assets/schedule.svg";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import { GET_ACTIVE_ORDER } from "../../../queries/cart.queries";
import { MOVE_STATE } from "../../../queries/checkout.queries";
import { useTranslation } from "react-i18next";
import DeliveryScheduler from "../delivery-scheduler/delivery-scheduler.component";
import { SET_ORDER_TIME_WINDOW } from "../../../mutations/set-order-time-window";
import Loader from "../../common/loader/loader.component";
import { ErrorHandlingContext } from "../../../context/error-handling.context";

const useStyles = makeStyles({
  button: {
    height: 56,
    width: 240,
    border: "none",
    textAlign: "center",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },
});

const DeliveryProcess = ({ setProcesses, processes, onEditDeliveryTime }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setErrorHandlingState } = useContext(ErrorHandlingContext);

  const { stage3DeliverTo } = processes;

  const [closeAccordion, setCloseAccordion] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [pickDays, setPickDays] = useState(0);
  const [hourPicked, onHourPicked] = useState("1");
  const [date, setDate] = useState(null);
  const [timeDescription, setTimeDescription] = useState(null);

  useQuery(GET_ACTIVE_ORDER, {
    onCompleted: async (data) => {
      if (data?.activeOrder?.state === "ArrangingPayment") {
        await moveStateToAddingItems();
      }
    },
  });

  const [moveStateToAddingItems] = useMutation(MOVE_STATE, {
    variables: {
      state: "AddingItems",
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }],
  });

  const [setOrderTimeWindow, { loading: setTimeWindowLoader }] = useMutation(
    SET_ORDER_TIME_WINDOW,
    {
      variables: { timeWindowId: hourPicked },
      onCompleted: (data) => {
        if (data?.setOrderTimeWindow?.errorCode) {
          setErrorHandlingState({
            showError: true,
            data: data?.setOrderTimeWindow?.message,
          });
        }
        setDate(data?.setOrderTimeWindow?.deliveryTime);
        setTimeDescription(data?.setOrderTimeWindow?.timeWindow?.description);
        setIsEdit(true);
      },
    }
  );

  useEffect(() => {
    if (!hourPicked) return;

    setOrderTimeWindow();
  }, [hourPicked])

  useEffect(() => {
    setCloseAccordion(stage3DeliverTo);
  }, [stage3DeliverTo]);

  const onContinue = async () => {
    try {
      await setOrderTimeWindow();
    } catch (err) {
      setIsEdit(false);
      setErrorHandlingState({
        showError: true,
        data: "An internal error has occurred",
      });
    }
  };

  return (
    <Accordion
      style={{
        borderRadius: "16px",
        padding: 30,
        boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
        marginBottom: 24,
        borderBottom: "none",
      }}
      expanded={closeAccordion}
      disabled={!stage3DeliverTo}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              opacity: stage3DeliverTo ? 1 : 0.64,
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ScheduleIcon style={{ marginRight: 16 }} />
                <span style={{ ...h3 }}>{t("common.deliveryTime")}</span>
              </div>
              {!closeAccordion && <div
                style={{
                  ...h6Regular,
                  opacity: 0.8,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span style={{ ...h6Regular, opacity: 0.8 }}>
                  {date ? getShortMonthAndDay(date) : null}, from {timeDescription?.split('-')[0]} to {timeDescription?.split('-')[1]}
                </span>
              </div>
              }
            </div>
            <div>
              {isEdit && !closeAccordion ? (
                <span
                  style={{
                    ...h6Medium,
                    color: "#80BB34",
                  }}
                  onClick={() => {
                    setCloseAccordion(true);
                    onEditDeliveryTime();
                  }}
                >
                  {t("common.edit")}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <DeliveryScheduler pickDays={pickDays} onHourPicked={onHourPicked} date={date} />
          <button
            className={classes.button}
            style={{
              background: hourPicked !== null ? "#80BB34" : "lightgray",
            }}
            onClick={() => {
              setProcesses();
              setCloseAccordion(false);
              onContinue();
            }}
            disabled={!hourPicked}
          >
            {setTimeWindowLoader ? (
              <Loader color={"#B73232"} size={30} />
            ) : (
              t("common.Continue")
            )}
          </button>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default DeliveryProcess;

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { ReactComponent as BreadCrumbsArrow } from "../../../assets/rightArrow.svg";
import { FilterContext } from "../../../context/filterContext";
import { ReactComponent as BreadCrumbsArrowLeft } from "../../../assets/leftArrow.svg";

import "./catalog-filters-tags.component.css";

const CatalogFiltersTags = (props) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const location = useLocation();

  const { searchState, setSearchState } = useContext(FilterContext);

  const { facetValueIds, collectionId, term } = searchState;

  const removeFacetsValues = (typename, id) => {
    if (typename === "FacetValue") {
      const facetValueId = id;

      setSearchState({
        ...searchState,
        term: "",
        facetValueIds: facetValueIds.filter((fv) => {
          return fv.id !== facetValueId;
        }),
        previousPage: location?.pathname
      });
    } else if (typename === "Collection") {
      setSearchState({
        ...searchState,
        facetValueIds: [],
        collectionId: id,
        term: "",
        previousPage: location?.pathname
      });
    } else if (typename === "search") {
      setSearchState({
        isInit: true,
        facetFaluesPerCollectionInit: true,
        facetValuesDefaultFilters: [],
        facetFaluesDefaultPerCollection: [],
        facetValueIdsPerCollection: [],
        facetValueIds: [],
        collectionId: null,
        term: "",
        previousPage: location?.pathname
      });
    }
  };

  const onBackToHome = () => navigate.push(`/`);

  if (!facetValueIds?.length && collectionId === null) {
    return <div className="catalog-main__back-home-btn h6-medium pointer" onClick={onBackToHome}>
      <BreadCrumbsArrowLeft style={{ width: 16, height: 16 }} />
      {t('catalog.backToHome')}
    </div>
  };

  const combinedValues = [term, collectionId, ...facetValueIds];

  const currentCollectionFilter = <div onClick={() => removeFacetsValues("Collection", collectionId)} style={{ display: "flex", gap: 16, alignItems: "center" }}>
    <BreadCrumbsArrow />
    <span className={`${facetValueIds[0] ? "h6-medium pointer" : "h6-regular"}`}>{collectionId?.name}</span>
  </div>;

  const currentFacetFilter = <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
    <BreadCrumbsArrow />
    <span className="h6-regular">{facetValueIds[0]?.name}</span>
  </div>;

  const breadcrumbs = <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
    <span
      onClick={() => removeFacetsValues("search", undefined)}
      className="h6-medium pointer breadcrumbs-title"
    >
      {t("header.categories")}
    </span>
    {collectionId ? currentCollectionFilter : null}
    {facetValueIds[0] ? currentFacetFilter : null}
  </div>;

  return combinedValues.length ? (
    <div {...props}>
      {breadcrumbs}
      <div className="catalog-filters-tags--responsive-line"></div>
    </div>
  ) : (
    ""
  );
};

export default CatalogFiltersTags;

import React from "react";

import "./supplier-page-details.component.css";

const SupplierPageDetails = ({ merchant }) => {
  return (
    <div className="SPD">
      <div className="SPD__img">
        <img src={merchant?.preview} alt={merchant?.name}></img>
      </div>
      <span className="SPD__title">{merchant?.name}</span>
      <span
        className="SPD__description"
        dangerouslySetInnerHTML={{ __html: merchant?.description }}
      ></span>
    </div>
  );
};

export default SupplierPageDetails;

import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { ReactComponent as ArrowRightGreen } from "./arrow-right-green.svg";

const CatalogSideFilters = ({ category, subCategories, categoryClicked, categories }) => {
    const [filterSelected, setFilterSelected] = useState(null);

    const onCategoryClicked = (e) => {
        const categorySelected = categories.find(c => c.name === category);
        setFilterSelected(category);
        categoryClicked(categorySelected);
    };

    const onSubCategorySelected = (subCategory) => {
        const categorySelected = categories.find(c => c.name === category);
        categoryClicked({ ...subCategory, category: categorySelected });
        setFilterSelected(subCategory);
    }

    return (
        <Accordion
            expanded="true"
            style={{ background: 'transparent', boxShadow: 'none', border: 'none', padding: 0, position: 'unset', margin: 0 }}>
            <AccordionSummary
                aria-controls="panel1c-content"
                id="panel1c-header"
                style={{ minHeight: 20 }}
            >
                <span className='h6-medium pointer' style={{ color: "#80BB34" }} onClick={onCategoryClicked}>{category}</span>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0 0 0 36px', display: 'flex', flexDirection: 'column', gap: 16, marginBottom: 12 }}>
                {subCategories?.map(subCategory => (
                    <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                        onClick={() => onSubCategorySelected(subCategory?.facetValue)}>
                        <span className='h6-regular'>{subCategory?.facetValue?.name}</span>
                        <span className='h6-regular'>{subCategory?.count}</span>
                    </div>
                ))}
            </AccordionDetails>
        </Accordion>
    );
};

export default CatalogSideFilters;
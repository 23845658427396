import { gql } from "@apollo/client";

export const VALIDATE_SHIPPING_ADDRESS = gql`
  mutation validateShippingAddress(
    $fullName: String
    $company: String
    $streetLine1: String!
    $streetLine2: String
    $city: String
    $province: String
    $postalCode: String
    $countryCode: String!
    $phoneNumber: String
    $defaultShippingAddress: Boolean
    $defaultBillingAddress: Boolean
  ) {
    validateShippingAddress(
      input: {
        fullName: $fullName
        company: $company
        streetLine1: $streetLine1
        streetLine2: $streetLine2
        city: $city
        province: $province
        postalCode: $postalCode
        countryCode: $countryCode
        phoneNumber: $phoneNumber
        defaultShippingAddress: $defaultShippingAddress
        defaultBillingAddress: $defaultBillingAddress
      }
    ) {
      ... on Success {
        success
      }
      ... on InvalidAddressError {
        errorCode
        message
        __typename
      }

      ... on UndeliverableAddressError {
        errorCode
        message
        __typename
      }

      ... on ErrorResult {
        errorCode
        message
        __typename
      }
      __typename
    }
  }
`;

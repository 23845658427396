import React, { useState } from "react";

const initialState = {
  showError: false,
  data: "",
};

const ErrorHandlingContext = React.createContext(initialState);

const ErrorHandlingContextProvider = ({ children }) => {
  const [errorHandlingState, setErrorHandlingState] = useState(initialState);

  return (
    <ErrorHandlingContext.Provider
      value={{ errorHandlingState, setErrorHandlingState }}
    >
      {children}
    </ErrorHandlingContext.Provider>
  );
};

export { ErrorHandlingContext, ErrorHandlingContextProvider };

import { makeStyles } from "@material-ui/core";
import React from "react";
import { h6Medium } from "../../../shared/constants";
import OrderTimeLine from "../../common/order-time-line/order-time-line.component";
import Loader from "../../common/loader/loader.component";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    borderRadius: 16,
    height: "auto",
    background: "#FFFFFF",
    marginBottom: 16,
    padding: 24,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },
});

const AccountOrderRes = ({ orders, addToCardLoader, onAddToBasket, setIndex, index, onCancelOrder, cancelOrderLoader }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return orders.length
    ? orders.map((o, i) => {
      return (
        <div className={classes.container} key={i}>
          <div className={classes.header}>
            <span style={{ ...h6Medium }}>
              {o.createdAt.substring(0, 10)}
            </span>
            <span style={{ ...h6Medium }}>
              ₪ {(o.totalWithTax / 100).toFixed(2)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              ...h6Medium,
              marginBottom: 16,
              justifyContent: "center",
            }}
          >
            <OrderTimeLine state={o?.state} />
          </div>
          {o.state !== "Cancelled" ? <div
            style={{
              color: "#80BB34",
              ...h6Medium,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 10, height: 50 }}>
              {addToCardLoader && i === index ?
                <Loader color={"#B73232"} size="15" />
                : <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIndex(i);
                    onAddToBasket(o);
                  }}>
                  {t("account.Add to basket")}
                </span>}
              {o?.state === 'Open' ?
                <>
                  {cancelOrderLoader ?
                    <Loader color={"#B73232"} size="15" />
                    : <span style={{ color: "#f50050", cursor: "pointer" }} onClick={() => { onCancelOrder(o?.id) }}>Cancel Order</span>}
                </> : null}
            </div>
          </div> : null}
        </div>
      );
    })
    : "No Products Available";
};

export default AccountOrderRes;

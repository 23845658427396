import React, { useState } from "react";

const initialState = {
  cartItemsAmount: 0,
  itemsInCart: [],
  isCartOpen: false,
  tempItemData:{
    id: "",
    shouldAdd:false
  }
};

const CartContext = React.createContext(initialState);

const CartContextProvider = ({ children }) => {
  const [cartState, setCartState] = useState(initialState);

  return (
    <CartContext.Provider value={{ cartState, setCartState }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartContextProvider };

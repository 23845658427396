import { gql } from "@apollo/client";

export const DELETE_ADDRESS = gql`
mutation ($id: ID!) {
    deleteCustomerAddress(id:$id) {
      ...on Success {
        success
          }
    }
  }
`;
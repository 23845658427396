import React from 'react';
import { useTranslation } from "react-i18next";

import TextAreaComponent from "../../../components/common/text-area/text-area.component";


const DeliveryNotes = ({ onTextChange, ...props }) => {
    const { t } = useTranslation();
    const [deliveryNote, setDeliveryNote] = React.useState('');

    React.useEffect(() => {
        onTextChange(deliveryNote);
    }, [deliveryNote]);

    return <TextAreaComponent
        label={t("checkout.comments")}
        name="deliveryNote"
        type="string"
        handleChange={(e) => {
            setDeliveryNote(e.target.value)
        }}
        value={deliveryNote}
        margin={"54px 0px 0px 0px"}
        {...props}
    />
};

export default DeliveryNotes;
import { gql } from "@apollo/client";

export const CANCEL_ORDER = gql`
mutation ($id: ID!) {
    cancelOpenOrder(id: $id) {     
      ...on Order {
        id
      }
      ...on OrderStateTransitionError {
        transitionError
      }
    }
  }
`;
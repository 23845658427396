import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import {
    h4Medium,
    pointer,
    h5Medium,
} from "../../../shared/constants";
import FormInput from '../form-input/form-input.component';
import Loader from "../../common/loader/loader.component";

const useStyles = makeStyles({
    ChangeEmailPasswordContainer: {
        height: 312,
        width: 320,
        padding: 48,
        background: "#FFFFFF",
        borderRadius: 32
    },
    ChangeEmailPasswordHeader: {
        marginBottom: 32,
        display: "flex",
        justifyContent: "space-between",
    },
    ChangeEmailPasswordButton: {
        height: 56,
        width: "100%",
        border: "none",
        textAlign: "center",
        background: "#80BB34",
        borderRadius: 16,
        marginTop: 24,
        ...h5Medium,
        color: "#FFFFFF",
        ...pointer,
    }
});

const DEFAULT_UPDATED_DATA = {
    email: "",
    password: "",
    oldPassword: "",
    newPassword: ""
}

const ChangeEmailPassword = ({ isEmailType, handleCloseModal, onSubmit, updatePassLoader, changeEmailAddressLoader }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [updatedData, setUpdatedData] = useState(DEFAULT_UPDATED_DATA);
    const [showPassword, setShowPassword] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [passwordsUnmatched, setPasswordsUnmatched] = useState(false);

    useEffect(() => {
        if (isEmailType) {
            setIsDisabled(!updatedData.email || !updatedData.password);
        }
        else {
            setIsDisabled(!updatedData.oldPassword || !updatedData.newPassword || updatedData.oldPassword !== updatedData.newPassword);
            setPasswordsUnmatched(updatedData.oldPassword !== updatedData.newPassword)
        }
    }, [updatedData]);

    const onInputChanged = (e) => {
        const { value, name } = e?.target;

        setUpdatedData({
            ...updatedData,
            [name]: value
        });
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const cahngeOfEmail = <div>
        <FormInput
            name="email"
            type="string"
            value={updatedData.email}
            handleChange={onInputChanged}
            label={t("auth.Email")}
            margin={"0 0 48px 0px"}
            border={"1px solid rgba(0, 0, 0, 0.1)"}
        />
        <div style={{ position: "relative" }}>
            <FormInput
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={updatedData.password}
                handleChange={onInputChanged}
                label={t("auth.Password")}
                margin={"0 0 24px 0px"}
                optionalmessage={"To confirm email change"}
            />
            <span onClick={togglePasswordVisibility}
                style={{
                    position: "absolute",
                    right: 12,
                    top: 10,
                    cursor: pointer,
                    color: "#15222F",
                    opacity: 0.8
                }}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </span>
        </div>
    </div>

    const cahngeOfPassword = <div>
        <div style={{ position: "relative" }}>
            <FormInput
                name="oldPassword"
                type={showPassword ? 'text' : 'password'}
                value={updatedData.oldPassword}
                handleChange={onInputChanged}
                label={t("auth.oldPassword")}
                margin={`0 0 48px 0`}
            />
            <span onClick={togglePasswordVisibility}
                style={{
                    position: "absolute",
                    right: 12,
                    top: 10,
                    cursor: pointer,
                    color: "#15222F",
                    opacity: 0.8
                }}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </span>
        </div>
        <div style={{ position: "relative", height: 80 }}>
            <FormInput
                name="newPassword"
                type={showPassword ? 'text' : 'password'}
                value={updatedData.newPassword}
                handleChange={onInputChanged}
                label={t("auth.newPassword")}
                margin={`0 0 24px 0px`}
                message={passwordsUnmatched ? "Unmatched passwords" : ""}
            />
            <span onClick={togglePasswordVisibility}
                style={{
                    position: "absolute",
                    right: 12,
                    top: 10,
                    cursor: pointer,
                    color: "#15222F",
                    opacity: 0.8
                }}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </span>
        </div>
    </div>;

    const title = isEmailType ? t("common.changeOfEmail") : t("common.changeOfPassword");

    return <div className={classes.ChangeEmailPasswordContainer}>
        <div className={classes.ChangeEmailPasswordHeader} style={{ direction: t("css.side") }}>
            <span style={h4Medium}>{title}</span>
            <span className='pointer' onClick={() => { handleCloseModal() }}>
                <RemoveIcon style={{ height: 24, width: 24 }} />
            </span>
        </div>
        {isEmailType ? cahngeOfEmail : cahngeOfPassword}
        <div style={{ height: 80 }}>
            <button
                type="button"
                className={classes.ChangeEmailPasswordButton}
                disabled={isDisabled}
                style={{
                    background: isDisabled ? "grey" : "#80BB34",
                    opacity: isDisabled ? 0.16 : 1,
                }}
                onClick={() => onSubmit(updatedData)}
            >
                {updatePassLoader || changeEmailAddressLoader ? (
                    <Loader color={"#B73232"} size={20} />
                ) : (
                    t("common.Save")
                )}
            </button>
        </div>
    </div >
};

export default ChangeEmailPassword;
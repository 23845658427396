import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  h4Medium,
  h5Medium,
  h6Regular,
  h7Regular,
  pointer,
} from "../../../shared/constants";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";

const useStyles = makeStyles({
  container: {
    width: 340,
    height: "auto",
    background: "#FFFFFF",
    boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
    borderRadius: 32,
    padding: 40,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    ...h4Medium,
    marginBottom: 16,
  },
});

const AddPhone = ({
  onChangeNumber,
  handleClose,
  phoneValue,
  setPhoneValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div
        className={classes.header}
        style={{ direction: t("css.side"), marginBottom: 38 }}
      >
        <span>{t("common.phoneNumber")}</span>
        <span
          style={{ ...pointer }}
          onClick={() => {
            handleClose();
          }}
        >
          <RemoveIcon style={{ height: 24, width: 24 }} />
        </span>
      </div>

      <div
        style={{ marginBottom: 44, display: "flex", flexDirection: "column" }}
      >
        <span style={{ ...h7Regular, opacity: 0.4, direction: t("css.side") }}>
          {t("common.phoneNumber")}
        </span>

        <PhoneInput
          style={{
            ...h6Regular,
          }}
          defaultCountry="IL"
          withCountryCallingCode={true}
          international={true}
          placeholder="Enter phone number"
          value={phoneValue}
          onChange={setPhoneValue}
        />
      </div>

      <button
        style={{
          width: "100%",
          height: "56px",
          background:
            isValidPhoneNumber(`${phoneValue}`) === false
              ? "lightgray"
              : "#80BB34",
          color: "#ffffff",
          borderRadius: 16,
          border: "none",
          ...pointer,
          ...h5Medium,
          marginBottom: 32,
        }}
        onClick={() => {
          onChangeNumber(false);
        }}
        disabled={isValidPhoneNumber(`${phoneValue}`) === false ? true : false}
      >
        {t("common.Continue")}
      </button>
    </div>
  );
};

export default AddPhone;

import React, { useState } from "react";

export const DEFAULT_SORT = {
  name: 'ASC'
}
// useReducer//
const initialState = {
  isInit: true,
  facetFaluesPerCollectionInit: true,
  term: "",
  facetValuesDefaultFilters: [],
  facetFaluesDefaultPerCollection: [],
  facetValueIdsPerCollection: [],
  facetValueIds: [],
  collectionId: null,
  sort: DEFAULT_SORT,
  previousPage: "",
  itemName: ""
};

const FilterContext = React.createContext(initialState);

const FilterContextProvider = ({ children }) => {
  const [searchState, setSearchState] = useState(initialState);

  return (
    <FilterContext.Provider value={{ searchState, setSearchState }}>
      {children}
    </FilterContext.Provider>
  );
};

export { FilterContext, FilterContextProvider };

import { SwipeableDrawer } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import { FilterContext } from "../../context/filterContext";
import CartIndicator from "../cart/cart-indicator/cart-indicator.component";
import BasketIcon from "../common/svg/basket/basket";
import { ReactComponent as HeLogo } from '../../assets/logo_he.svg';
import { ReactComponent as EnLogo } from '../../assets/logo_en.svg';

import "./header-responsive.css";
import MenuDrawer from "./menu-drawer/menu-drawer.component";
import { AccountContext } from "../../context/account.context";
import { useHistory, useLocation } from "react-router-dom";

const HeaderResponsive = ({
  color,
  isCartOpen,
  toggleCart,
  handleOpenAddressModal,
}) => {
  const useStyles = makeStyles({
    svg: {
      "& svg path": {
        stroke: color,
      },
    },
  });

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();
  const menuSide = t("header.menuSide");
  const { searchState, setSearchState } = useContext(FilterContext);

  const { term } = searchState;

  const onSearchBox = (e) => {
    history.push({
      pathname: "/catalog",
    });
    setSearchState({
      isInit: true,
      facetFaluesPerCollectionInit: true,
      facetValuesDefaultFilters: [],
      facetFaluesDefaultPerCollection: [],
      facetValueIdsPerCollection: [],
      facetValueIds: [],
      collectionId: null,
      term: e.target.value,
      previousPage: location?.pathname
    });
  };

  const [state, setState] = useState({
    menuSide: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, 'menuSide': open });
  };

  return (
    <div>
      <React.Fragment>
        <div className="header-responsive">
          <div className="header-responsive__container">
            <div className="header-responsive__menu-container">
              <span className={`header-responsive__menu ${classes.svg}`}>
                <MenuIcon onClick={toggleDrawer(menuSide, true)} />
              </span>
              <div onClick={() => history.push("/")} style={{ cursor: "pointer" }}>
                {t("graphql.languageCode") === "en" ? <EnLogo width={50} height={50} /> : <HeLogo width={50} height={50} />}
              </div>
            </div>
            <div className="header-responsive__basket-container">
              <span
                className="h6-regular"
                style={{ color }}
                onClick={handleOpenAddressModal}
              >
                {t("header.deliveryAddress")}
              </span>
              <CartIndicator onClick={toggleCart} iconColor={color} />
            </div>
          </div>
          <input
            className="header-responsive__search"
            onChange={onSearchBox}
            value={term}
            placeholder={t("common.searchPlaceholder")}
          ></input>
          <SwipeableDrawer
            anchor={menuSide}
            open={state.menuSide}
            onClose={toggleDrawer(menuSide, false)}
            onOpen={toggleDrawer(menuSide, true)}
          >
            <MenuDrawer toggleDrawer={toggleDrawer} />
          </SwipeableDrawer>
        </div>
      </React.Fragment>
    </div>
  );
};

export default HeaderResponsive;

import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Radio from "@mui/material/Radio";

import {
  h4Medium,
  h5Light,
  h5Medium,
  h6Regular,
  pointer,
} from "../../../shared/constants";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import { ReactComponent as StarIcon } from "../../../assets/star.svg";

import { useHistory } from "react-router-dom";
import { AccountContext } from "../../../context/account.context";
import { UPDATE_ADDRESS } from "../../../queries/user.queries";
import { useMutation } from "@apollo/client";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    background: "#FFFFFF",
    width: 640,
    height: 350,
    padding: 48,
    borderRadius: 32,

    "@media (max-width:940px)": {
      width: 340,
    },
  },

  header: {
    display: "flex",
    height: 32,
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    marginBottom: 32,

    "@media (max-width:940px)": {
      height: 24,
    },
  },

  closeIcon: {
    width: 32,
    height: 32,
    cursor: pointer,

    "@media (max-width:940px)": {
      width: 16,
      height: 16,
    },
  },

  body: {
    display: "flex",
    flexDirection: "column",
    height: 270,
  },

  bodyTitles: {
    display: "flex",
    marginBottom: 32,

    "@media (max-width:940px)": {
      marginBottom: 21,
    },
  },

  bodyAddresses: {
    overflow: "auto",
  },

  addressDetails: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
  },

  addressDetailsBody: {
    display: "flex",
    flexDirection: "column",
  },
});

const HeaderAddress = ({ handleCloseAddressModal }) => {
  const classes = useStyles();
  // const history = useHistory();
  const { t } = useTranslation();

  const dir = t("css.side");

  const { accountState } = useContext(AccountContext);

  const { user } = accountState;

  const { addresses } = user;

  const userAddresses = addresses.filter(
    (a) => a.defaultShippingAddress === true
  );
  const id = userAddresses[0]?.id;

  const [selectedValue, setSelectedValue] = useState(id);

  let [updateAddress, { loading: updateAddressLoader }] = useMutation(
    UPDATE_ADDRESS,
    {
      variables: {
        id: selectedValue,
        defaultShippingAddress: true,
      },
      refetchQueries: [
        { query: GET_ACTIVE_USER }, // Query name
      ],
      // awaitRefetchQueries: true,
    }
  );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (id === selectedValue) return;
    updateAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
    disabled: updateAddressLoader,
  });

  // if (loading) return <Loader color={"#B73232"} size={50} />;

  return (
    <div className={classes.container} style={{ direction: dir }}>
      <div className={classes.header}>
        <span style={{ ...h4Medium, opacity: 0.8 }}>
          {t("account.deliveryTo")}
        </span>
        <div className="pointer" onClick={handleCloseAddressModal}>
          <RemoveIcon className={classes.closeIcon} />
        </div>
      </div>

      <div className={classes.body}>
        <div className={classes.bodyTitles}>
          <span
            style={{
              ...h5Medium,
              marginRight: dir === "ltr" ? 32 : 0,
              marginLeft: dir === "rtl" ? 32 : 0,
            }}
          >
            {t("checkout.Saved")}
          </span>

          {/* <span
            style={{ ...h5Light, opacity: 0.64, ...pointer }}
            onClick={() => {
              handleCloseAddressModal();
              history.push({
                pathname: "/account",
                state: {
                  // location state
                  page: "Addresses",
                },
              });
            }}
          >
            {t("checkout.Add New")}
          </span> */}
        </div>

        <div className={classes.bodyAddresses}>
          {addresses.length ? (
            addresses.map((a) => (
              <div key={a.id} className={classes.addressDetails}>
                <Radio {...controlProps(a.id)} color="success" />
                {a.defaultShippingAddress === true ? (
                  <div style={{ marginRight: 8 }}>
                    <StarIcon />
                  </div>
                ) : (
                  ""
                )}
                <div className={classes.addressDetailsBody}>
                  <span style={{ ...h6Regular, opacity: 0.8 }}>
                    {a.streetLine1}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <span
              style={{
                ...h5Medium,
                opacity: 0.8,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {t("account.noAdresses")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderAddress;

import React from "react";

import { ReactComponent as NoResultsIcon } from "../../../assets/no-results.svg";

import { ReactComponent as LeftArrowIcon } from "../../../assets/leftArrow.svg";

import "./no-results-page.component.css";
import { useTranslation } from "react-i18next";

const NoResultsPage = ({ title, descriprion, children, goBack }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="NRP__container">
        <span className="h4-medium">{title}</span>
        <span className="h6-regular NRP__description">{descriprion}</span>
        <NoResultsIcon />
        <div className="NRP__back pointer" onClick={goBack}>
          <LeftArrowIcon />
          <span className=" h5-medium">{t("common.Go back")}</span>
        </div>
      </div>
      {children}
    </React.Fragment>
  );
};

export default NoResultsPage;

import React, { useState } from "react";
import { Fade, makeStyles, Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";

import { ReactComponent as PlusIcon } from "../../../assets/plus.svg";
import { h6Medium, pointer } from "../../../shared/constants";

import { useTranslation } from "react-i18next";
import AddAddressForm from "./add-address-form.component";

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  addCard: {
    ...h6Medium,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 544,
    height: 110,
    border: "1px dashed lightgrey",
    borderRadius: 16,
    color: "#80BB34",
    padding: "40px 0",
  },
  plus: {
    width: 48,
    height: 48,
    marginBottom: 8,
    ...pointer,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },

  res: {
    "@media (min-width:940px)": {
      display: "none",
    },
  },

  reg: {
    "@media (max-width:940px)": {
      display: "none",
    },
  },
});

const AddAddress = ({ fetchLazy }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  return (
    <div className={classes.container}>
      {isEditMode ? (
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <AddAddressForm
              setIsEditMode={setIsEditMode}
              fetchLazy={fetchLazy}
              handleClose={handleClose}
            />
          </Fade>
        </Modal>
      ) : (
        <React.Fragment>
          <div className={classes.res}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#80BB34",
                ...h6Medium,
              }}
              onClick={() => {
                setIsEditMode(true);
                setOpen(true);
              }}
            >
              <PlusIcon style={{ ...pointer, paddingRight: 8 }} />
              {t("account.Add Address")}
            </div>
          </div>
          <div className={classes.reg}>
            <div
              className={classes.addCard}
              onClick={() => {
                setIsEditMode(true);
                setOpen(true);
              }}
            >
              <PlusIcon className={classes.plus} />
              <span>{t("account.Add Address")}</span>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default AddAddress;

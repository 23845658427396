/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

const assets = `assets {
  id
    createdAt
    updatedAt
    name
    fileSize
    type
    mimeType
    width
    height
    focalPoint {
      x
      y
    }
    preview
    source
}`;

const GET_ACTIVE_ORDER = gql`
  query GetActiveOrder {
    activeOrder {
      id
      code
      state
      active
      discounts {
        adjustmentSource
        type
        description
        amount
      }
      customer {
        id
        title
        firstName
        lastName
      }
      lines {
        id
        featuredAsset {
          id
          name
          preview
          __typename
        }
        unitPrice
        unitPriceWithTax
        quantity
        discountedLinePriceWithTax
        linePriceWithTax
        productVariant {
          id
          name
          ${assets}
          sku
          price
          priceWithTax
          featuredAsset {
            id
            name
            preview
            __typename
          }
          product {
            slug
            ${assets}
            variants {
              id
            }
          }
          __typename
        }
        __typename
      }
      shipping
      shippingLines {
        shippingMethod {
          id
        }
      }
      shippingWithTax
      state
      subTotal
      subTotalWithTax
      total
      totalQuantity
      totalWithTax
      __typename
    }
  }
`;

const GET_TOTAL_LINES = gql`
  query GetTotalLines {
    activeOrder {
      id
      lines {
        id
        __typename
      }
      __typename
    }
  }
`;

const Asset = `featuredAsset {
  id
  width
  height
  name
  preview
  focalPoint {
    x
    y
    __typename
  }
  __typename
}
`;

const Cart = `
    id
    code
    state
    active
    lines {
      id
      ${Asset}
      unitPrice
      unitPriceWithTax
      quantity
      linePriceWithTax
      discountedLinePriceWithTax
      productVariant {
        id
        name
        __typename
      }
      discounts {
        amount
        amountWithTax
        description
        adjustmentSource
        type
        __typename
      }
      __typename
    }
    totalQuantity
    subTotal
    subTotalWithTax
    total
    totalWithTax
    shipping
    shippingWithTax
    shippingLines {
      priceWithTax
      shippingMethod {
        id
        code
        name
        description
        __typename
      }
      __typename
    }
    discounts {
      amount
      amountWithTax
      description
      adjustmentSource
      type
      __typename
    }
    __typename
`;

const ADD_TO_CART = gql`
  mutation AddToCart($variantId: ID!, $qty: Int!) {
    addItemToOrder(productVariantId: $variantId, quantity: $qty) {
      ... on Order {
        ${Cart}
      }
      ... on ErrorResult {
        errorCode
        message
        __typename
      }
      ... on InsufficientStockError {
        order {
          ${Cart}
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

const REMOVE_ALL_LINES_FROM_CART = gql`
mutation {  
  removeAllOrderLines{
    ... on Order {
      ${Cart}
    }
    ... on ErrorResult {
      errorCode
      message
      __typename
    }
    __typename
  }
}`;

const REMOVE_FROM_CART = gql`
mutation AdjustItemQuantity($id: ID!, $qty: Int!) {  
  adjustOrderLine(orderLineId: $id, quantity: $qty) {
    ... on Order {
      ${Cart}
    }
    ... on ErrorResult {
      errorCode
      message
      __typename
    }
    __typename
  }
}`;

const REMOVE_ORDER_LINE = gql`
mutation RemoveItemFromCart($id: ID!) {
  removeOrderLine(orderLineId: $id) {
    ... on Order {
      ${Cart}
    }
    ... on ErrorResult {
      errorCode
      message
      __typename
    }
    __typename
  }
}`;

const GET_ORDER_BY_CODE = gql`
  query GetOrder($code: String!) {
    orderByCode(code: $code) {
      ...Cart
      shippingAddress {
        ...OrderAddress
        __typename
      }
      billingAddress {
        ...OrderAddress
        __typename
      }
      __typename
    }
  }
  fragment Cart on Order {
    id
    code
    state
    active
    customer {
      firstName,
      lastName
    }
    timeWindow {
      description
    }
    deliveryTime
    lines {
      id
      featuredAsset {
        ...Asset
        __typename
      }
      unitPrice
      unitPriceWithTax
      quantity
      linePriceWithTax
      discountedLinePriceWithTax
      productVariant {
        id
        name
        __typename
      }
      discounts {
        amount
        amountWithTax
        description
        adjustmentSource
        type
        __typename
      }
      __typename
    }
    totalQuantity
    subTotal
    subTotalWithTax
    total
    totalWithTax
    shipping
    shippingWithTax
    shippingLines {
      priceWithTax
      shippingMethod {
        id
        code
        name
        description
        __typename
      }
      __typename
    }
    discounts {
      amount
      amountWithTax
      description
      adjustmentSource
      type
      __typename
    }
    __typename
  }
  fragment Asset on Asset {
    id
    width
    height
    name
    preview
    focalPoint {
      x
      y
      __typename
    }
    __typename
  }
  fragment OrderAddress on OrderAddress {
    fullName
    company
    streetLine1
    streetLine2
    city
    province
    postalCode
    country
    phoneNumber
    __typename
  }
`;

export {
  GET_ACTIVE_ORDER,
  GET_TOTAL_LINES,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REMOVE_ALL_LINES_FROM_CART,
  REMOVE_ORDER_LINE,
  GET_ORDER_BY_CODE,
};

import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";

import { FilterContext } from "../../../context/filterContext";
import Checkbox from "../../common/checkbox/checkbox";
import CatalogSideFilters from '../catalog-side-filters/catalog-side-filters';
import { GET_COLLECTIONS } from '../../../queries/queries';

const CatalogSubFilters = ({
  filters,
  updateFacetsValues,
  removeFacetsValues,
}) => {
  const { searchState } = useContext(FilterContext);
  const [categories, setCategories] = useState([]);
  const { facetValueIds } = searchState;

  const { data } = useQuery(GET_COLLECTIONS);

  React.useEffect(() => {
    if (data?.collections?.items) {
      let categories = data?.collections?.items?.filter(
        (c) => c?.name !== "Categories"
      );
      categories = data?.collections?.items?.filter(
        (c) => c?.parent?.slug === "categories"
      );
      setCategories(categories);
    }
  }, [data]);

  const filetrsKeys = Object.keys(filters);

  const categoryClicked = (facet) => {
    let count = 0;
    if (facet.__typename === "FacetValue") {
      const facetDetails = facet?.facet;
      const name = facetDetails?.name;
      const filteredFacet = filters[name].find(value => value?.facetValue?.id === facet?.id);
      count = filteredFacet?.count;
    }
    if (facet.__typename === "Collection") {
      const name = facet?.name;
      const filteredCollection = filters[name];
      count = filteredCollection.reduce((acc, current) => {
        acc = acc + current?.count;
        return acc;
      }, 0);
    }

    updateFacetsValues({ ...facet, count });
  };

  return (
    <div>
      {
        filetrsKeys.map(key => <CatalogSideFilters
          category={key}
          subCategories={filters[key]}
          categoryClicked={categoryClicked}
          categories={categories} />)
      }
    </div>
  );
};

export default CatalogSubFilters;

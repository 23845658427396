import { TextareaAutosize } from "@material-ui/core";
import React from "react";
import { h6Regular } from "../../../shared/constants";

import "../form-input/form-input.component.css";

const TextAreaComponent = ({ handleChange, label, margin, value, name, ...props }) => {
  return (
    <div className={`group`} style={{ margin: margin || "45px 0" }}>
      <TextareaAutosize
        onChange={handleChange}
        value={value}
        name={name}
        style={{
          ...h6Regular,
          resize: "none",
          color: "#000000CC",
          width: "100%",
          boxSizing: "border-box",
          borderBottom: "2px solid rgba(21, 34, 47, 0.08)",
        }}
        className={`form-input`}
        {...props}
      />
      {label ? (
        <label
          className={`h6-regular form-input-label ${value?.length ? "shrink" : ""
            }`}
        >
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default TextAreaComponent;

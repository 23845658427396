import React, { useState, useEffect, useRef } from 'react';
// import SearchIcon from '@material-ui/icons/Search';
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom";

import { GET_ITEMS_WITH_FILTERS } from "../../../queries/queries";
import debounce from 'lodash/debounce';
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";

import './search-dropdown.css';
import Loader from '../../../components/common/loader/loader.component';
import upsetIcon from './upset.png';
import SearchListItem from './search-list-item/search-list-item';

const SearchDropdown = ({ onSelect, onEnter }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        setSearchTerm('');
        setIsOpen(false);
    }, [location.pathname]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setSearchTerm('')
        toggleDropdown(false)
        onSelect(option);
    };

    const handleEnterKeyPress = () => {
        setSearchTerm('')
        setIsOpen(false);
        onEnter(searchTerm);
    };

    const handleInputClick = (e) => {
        if (!searchTerm.trim()) return;

        e.stopPropagation(); // Stop the click event from propagating to the document
        setIsOpen(true);
    };

    // Debounce the search term
    const debouncedSetSearchTerm = debounce((term) => {
        setDebouncedSearchTerm(term);
    }, 1000); // Adjust the debounce time as needed

    // Update the debounced search term when the user types
    useEffect(() => {
        if (!searchTerm.trim()) return;

        debouncedSetSearchTerm(searchTerm);
        return () => debouncedSetSearchTerm.cancel(); // Cleanup the debounced function on unmount
    }, [searchTerm, debouncedSetSearchTerm]);

    // Fetch data using the debounced search term
    const { loading, error, data, refetch } = useQuery(GET_ITEMS_WITH_FILTERS, {
        variables: { term: debouncedSearchTerm, groupByProduct: false },
        fetchPolicy: "no-cache"
    });

    const searchResultsList = data?.search?.items?.length <= 5 ? data?.search?.items?.map((option) => (
        <li key={option.slug} onClick={() => handleOptionClick(option)}>
            <SearchListItem item={option} />
        </li>
    )) : <>
        {data?.search?.items.slice(0, 5)?.map((option) => (
            <li key={option.slug} onClick={() => handleOptionClick(option)}>
                <SearchListItem item={option} />
            </li>
        ))}
        <li>
            <span className="h6-medium pointer show-full-list" onClick={handleEnterKeyPress}>
                {t("header.showFullList", {
                    length: data?.search?.items?.length,
                })}
            </span>
        </li>
    </>;

    const emptyStateMessage = <li key='empty' className='search__empty-state h6-regular'>
        <img src={upsetIcon} alt='upset_icon' />
        <span>{t('catalog.noMatchingProducts')}</span>
    </li>;

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <SearchIcon style={{ position: 'absolute', top: 15, left: 13, width: 25, height: 25, zIndex: 1 }} />
            <div className="dropdown-content">
                {loading && <div style={{ position: 'absolute', top: 15, right: 13 }}><Loader color={"#B73232"} size={25} /></div>}
                <input
                    placeholder={t("common.searchPlaceholder")}
                    type="text"
                    value={searchTerm}
                    style={{
                        padding: 25,
                        border: 'none',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        background: '#ffffff',
                        boxShadow: '0px 16px 32px rgba(29, 38, 102, 0.08)',
                        borderRadius: isOpen && !loading ? "30px 30px 0px 0px" : 30,
                        height: 56,
                        fontSize: 24,
                        padding: '0px 16px 0px 40px',
                        width: '100%',
                        boxSizing: 'border-box',
                        caretColor: '#80BB34'
                    }}
                    className="h6-regular"
                    onChange={(e) => {
                        setSelectedOption(null);
                        setSearchTerm(e.target.value);
                        setIsOpen(!!e.target.value.trim())
                    }}
                    onClick={handleInputClick}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleEnterKeyPress();
                        }
                    }}
                />
                {loading ? null :
                    <>
                        {!!searchTerm.trim() && isOpen ?
                            <ul className="options-list" ref={dropdownRef}>
                                {
                                    data?.search?.items?.length ? searchResultsList : emptyStateMessage
                                }
                            </ul> :
                            null
                        }
                    </>}
            </div>
        </div>
    );
}

export default SearchDropdown;
import { makeStyles } from "@material-ui/core";
import React from "react";

import "./form-input.component.css";

const FormInput = ({ handleChange, label, margin, ...otherProps }) => {
  const useStyles = makeStyles({
    formInput: {
      maxWidth: otherProps?.maxWidth || "100%",
    },
    valid: {
      color: "black",
      borderBottom: otherProps?.border || "1px solid rgba(0, 0, 0, 0.08)",
    },
  });

  const classes = useStyles();
  return (
    <div className={`group`} style={{ margin: margin || "45px 0" }}>
      <input
        className={`form-input h6-regular ${classes.formInput} ${
          otherProps.message && otherProps.message.length
            ? "error"
            : classes.valid
        }`}
        onChange={handleChange}
        {...otherProps}
      />
      {label ? (
        <label
          className={`h6-regular ${otherProps.value?.length ? "shrink" : ""} ${
            otherProps.message && otherProps.message?.length ? "error" : "valid"
          } form-input-label`}
        >
          {label}
        </label>
      ) : null}
      <span className="FI__error h6-regular">
        {otherProps.message && otherProps.message.length
          ? otherProps.message
          : ""}
      </span>
      <span className="h7-regular" style={{ opacity: 0.4 }}>
        {otherProps.optionalmessage && otherProps.optionalmessage.length
          ? otherProps.optionalmessage
          : ""}
      </span>
    </div>
  );
};

export default FormInput;

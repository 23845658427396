import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import { h4Medium, h5Medium, pointer } from "../../../shared/constants";
import FormInput from "../../common/form-input/form-input.component";

import "./add-card.component.css";
import Checkbox from "../../common/checkbox/checkbox";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  button: {
    height: 56,
    width: "100%",
    border: "none",
    textAlign: "center",
    background: "#80BB34",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
    borderRadius: 32,
    width: 416,
    height: 524,
    padding: 48,
    overflow: "auto",
  },

  header: {
    display: "flex",
    height: 56,
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",

    "@media (max-width:940px)": {
      height: 32,
    },
  },

  headerTitles: {
    ...h4Medium,
  },

  secondLineContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 55,
  },

  expirationDate: {
    background: "none",
    backgroundColor: "white",
    color: "grey",
    position: "relative",
    width: 140,
    border: "none",
    borderRadius: 0,
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "10px 10px 10px 5px",
  },
});

const AddCard = ({ handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expiration: "",
    cvv: "",
    cardName: "",
    defaultCard: false,
  });

  const { defaultCard } = cardDetails;

  const handleChange = (e) => {
    const { value, name } = e.target;

    setCardDetails({ ...cardDetails, [name]: value });
  };

  const header = (
    <div className={classes.header}>
      <div className={classes.headerTitles}>
        <span>Add Card</span>
      </div>
      <div className="pointer" onClick={handleClose}>
        <RemoveIcon
          className={classes.closeIcon}
          style={{ height: 24, width: 24 }}
        />
      </div>
    </div>
  );

  const body = (
    <div style={{ marginRight: 36 }}>
      <FormInput
        name="cardNumber"
        type="number"
        value={cardDetails.cardNumber}
        handleChange={handleChange}
        label="Card Number"
        border="1px solid rgba(0, 0, 0, 0.1)"
        maxWidth={"100%"}
      />
      <div className={classes.secondLineContainer}>
        <div className={classes.expirationDate}>
          <label
            className="expiration-label h7-regular"
            style={{ opacity: 0.4 }}
          >
            {t("account.Expiration")}
          </label>
          <input
            defaultValue="MM"
            maxLength="2"
            minLength="2"
            className="expiration-input"
          />
          <span style={{ width: 20 }}> / </span>
          <input
            defaultValue="YYYY"
            maxLength="4"
            minLength="4"
            className="expiration-input-year"
          />
        </div>
        <FormInput
          name="cvv"
          type="number"
          value={cardDetails.cvv}
          handleChange={handleChange}
          label="CVV"
          border="1px solid rgba(0, 0, 0, 0.1)"
          maxWidth={140}
        />
      </div>
      <FormInput
        name="cardName"
        type="string"
        value={cardDetails.cardName}
        handleChange={handleChange}
        label="Name On Card"
        border="1px solid rgba(0, 0, 0, 0.1)"
        maxWidth={"100%"}
      />

      <Checkbox
        value={t("account.Make It Default Card")}
        updateFacetsValues={(e) =>
          setCardDetails({
            ...cardDetails,
            defaultCard: !defaultCard,
          })
        }
        removeFacetsValues={(e) =>
          setCardDetails({
            ...cardDetails,
            defaultCard: !defaultCard,
          })
        }
        facetValue={false}
      />
    </div>
  );

  const footer = (
    <div className="buttons">
      <button type="submit" className={classes.button}>
        {t("common.Save")}
      </button>
    </div>
  );
  return (
    <div className={classes.container}>
      {header}
      {body}
      {footer}
    </div>
  );
};

export default AddCard;

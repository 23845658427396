import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Radio from "@mui/material/Radio";

import { h5Medium, h6Regular } from "../../../shared/constants";
import { useQuery } from "@apollo/client";
import { GET_TIME_WINDOWS } from "../../../queries/time-widows.query";
import useFormatedDate from './useNextDayDate';

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  deliveryCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 150,
  },
  title: {
    ...h5Medium,
    display: "flex",
    marginBottom: 18,
  },
  hour: {
    display: "flex",
    ...h6Regular,
  },
});

const DeliveryScheduler = ({ onHourPicked, date = null }) => {
  const classes = useStyles();
  const { getFormattedDate } = useFormatedDate();

  const [timesAvailable, setTimesAvailable] = useState([]);
  const [selectedValue, setSelectedValue] = useState("1");
  const [updatedDate, setUpdatedDate] = useState(date);

  useEffect(() => setUpdatedDate(date), [date])

  const controlProps = (id) => ({
    checked: selectedValue === id,
    onChange: () => {
      setSelectedValue(id);
      onHourPicked(id);
    },
    value: id,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": id },
  });

  useQuery(GET_TIME_WINDOWS, {
    onCompleted: (data) => {
      setTimesAvailable(data.timeWindows);
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.deliveryCol}>
        <span className={classes.title}>{updatedDate ? getFormattedDate(updatedDate) : null}</span>
        {timesAvailable?.map((o, i) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              key={i}
            >
              <div
                style={{
                  height: 35,
                  width: 55,
                }}
              >
                <Radio {...controlProps(`${o.id}`)} color="success" />
              </div>
              <div className={classes.hour}>{o.description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeliveryScheduler;

import { Backdrop, Fade, makeStyles, Modal } from "@material-ui/core";
import React, { useState } from "react";

import { h6Medium, pointer } from "../../../shared/constants";

import { ReactComponent as PlusIcon } from "../../../assets/plus.svg";
import AddCard from "../add-card/add-card.component";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginBottom: 40,
  },
  content: {
    display: "flex",
  },
  addCard: {
    ...h6Medium,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 540,
    width: "100%",
    height: 300,
    border: "1px dashed lightgrey",
    borderRadius: 16,
    color: "#80BB34",
  },
  plus: {
    width: 48,
    height: 48,
    marginBottom: 24,
    ...pointer,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reg: {
    "@media (max-width:940px)": {
      display: "none",
    },
  },
  res: {
    "@media (min-width:940px)": {
      display: "none",
    },
  },
});

const AccountBankCards = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <span className={`h2 ${classes.title} ${classes.reg}`}>
          {t("account.BankCards")}
        </span>
        <div className={classes.res}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWhight: "bold",
              marginBottom: 16,
            }}
          >
            <span className="h3">{t("account.BankCards")}</span>
            <div
              onClick={() => {
                setOpen(true);
              }}
              className={`${classes.res}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#80BB34",
                ...h6Medium,
              }}
            >
              <PlusIcon />
              <span style={{ ...pointer, paddingRight: 8 }}>
                {t("account.AddCard")}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${classes.addCard} ${classes.reg}`}
          onClick={() => {
            setOpen(true);
          }}
        >
          <PlusIcon className={classes.plus} />
          <span>{t("account.AddCard")}</span>
        </div>
      </div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <AddCard handleClose={handleClose} />
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default AccountBankCards;

import React from "react";
import { useTranslation } from "react-i18next";
import "./checkbox.css";

function Checkbox({
  updateFacetsValues,
  removeFacetsValues,
  value,
  facetValue,
  isChecked,
}) {
  const { t } = useTranslation();
  const handleChange = (e) => {
    e.target.checked
      ? updateFacetsValues(facetValue)
      : removeFacetsValues(facetValue);
  };

  let checkbox = (
    <input type="checkbox" checked={isChecked} onChange={handleChange} />
  );

  return (
    <label className={`container h6-regular ${t("css.checkbox")}`}>
      {checkbox}
      <span className={`checkmark ${t("css.checkmarkAfter")}`}></span>
      {value}
    </label>
  );
}

export default Checkbox;

import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { h3 } from "../../../shared/constants";
import PersonalInfo from '../../common/personal-info/personal-info.component';
import AccountSettingsSecurity from '../account-settings-security/account-settings-security.jsx';

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginBottom: 40,
  },
  personalInfo: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    borderRadius: 16,
    width: "auto",
    height: "auto",
    padding: 40,
    marginBottom: 48
  },
  innerTitles: {
    ...h3,
    marginBottom: 40,
  }
});

const AccountSettings = () => {
  const classes = useStyles();
  const { t } = useTranslation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.container}>
      <span className={`h2 ${classes.title}`}>{t("account.Settings")}</span>
      <AccountSettingsSecurity />
      <div className={classes.personalInfo}>
        <span className={classes.innerTitles}>
          {t("account.Personal Info")}
        </span>
        <PersonalInfo />
      </div>
    </div>
  );
};

export default AccountSettings;

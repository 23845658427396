import { gql } from "@apollo/client";

export const SEND_VERIFICATION_CODE_TO_CUSTOMER = gql`
    mutation SendVerificationCodeToCustomer($phoneNumber: String!) {
        sendVerificationCodeToCustomer(phoneNumber: $phoneNumber) {

            ... on Success {
                success
            }
            
            ... on InvalidPhoneNumberError {
                errorCode
                message
            }
    }
}`;

export const VERIFY_CUSTOMER_HPHONE_NUMBER = gql`
    mutation VerifyCustomerPhoneNumber(
        $phoneNumber: String!
        $verificationCode: String!) {
        verifyCustomerPhoneNumber(
            phoneNumber: $phoneNumber
            verificationCode: $verificationCode) {

            ... on Success {
                success
            }
        
            ... on PhoneNumberVerificationError {
                errorCode
                message
        }
    }
}`;

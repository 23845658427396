import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import { h2, h3, h5Regular } from "../../../shared/constants";
import ItemCard from "../../common/item-card/item-card";
import { AccountContext } from '../../../context/account.context';

import "./home-carousel.component.css";

const sourceA = require("./carouselBG.png").default;
const itemImage = require("./itemImage.png").default;
const sourceB = require("./carouselBG.png").default;

const dataArray = [{ source: sourceA }, { source: sourceB }];

const useStyles = makeStyles({
  main: {
    padding: "0px 40px",
    marginBottom: 32,
    "@media (max-width:1180px)": {
      padding: "0px 16px",
    },
  },
  container: {
    height: "770px",
    position: "relative",
    top: "0px",
    width: "calc(100% + 80px)",
    "@media (max-width:1180px)": {
      height: 640,
    },
    "@media (max-width:940px)": {
      width: "calc(100% + 56px)",
    },
  },
  image: {
    position: "absolute",
    top: "0px",
    width: "calc(100% + 80px)",
    zIndex: -100,
    height: "100%",
    objectFit: "cover",
    "@media (max-width:940px)": {
      width: "calc(100% + 56px)",
    },
  },
  innerDiv: {
    height: 750,
    padding: "0px 80px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:1180px)": {
      height: 550,
      "@media (max-width:940px)": {
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 56,
      },
    },
  },
  title: {
    ...h2,
    color: "#FFFFFF",
    "@media (max-width:940px)": {
      ...h3,
      color: "#FFFFFF",
    },
  },
  subTitle: {
    ...h5Regular,
    color: "#FFFFFF",
    opacity: 0.64,
    marginTop: 32,
    "@media (max-width:940px)": {
      marginTop: 24,
    },
  },
  sectionA: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 427,
  },
  indicators: {
    position: "absolute",
    bottom: "64px",
    "@media (max-width:1180px)": {
      position: "absolute",
      bottom: "56px",
    },
  },
  desktop: {
    "@media (max-width:940px)": {
      display: "none",
    },
  },
  mobile: {
    "@media (min-width:940px)": {
      display: "none",
    },
    "@media (max-width:940px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
});

// const styles = {
//   itemCard: {
//     height: 356,
//     maxHeight: 356,
//     width: 310,
//     maxWidth: 310,
//   },
//   itemCardRes: {
//     height: 270,
//     maxHeight: 270,
//     width: 215,
//     maxWidth: 215,
//   },
//   imageSection: {
//     height: 168,
//     maxHeight: 168,
//     width: 262,
//     maxWidth: 262,
//   },
//   imageSectionRes: {
//     height: 120,
//     maxHeight: 120,
//     width: 200,
//     maxWidth: 200,
//   },
// };

const HomeCarousel = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={`${classes.container} ${t("home.carouselStyle")}`}>
        <Carousel
          autoPlay={false}
          swipe={true}
          interval={3000}
          animation="slide"
          indicators={true}
          navButtonsAlwaysVisible={false}
          indicatorContainerProps={{
            className: `${classes.indicators}`,
          }}
        >
          {dataArray.map((item) => (
            <React.Fragment>
              <div
                className={`${classes.container} ${t("home.carouselStyle")} ${classes.desktop
                  }`}
              >
                <CarouselItemDesktop item={item} classes={classes} />
              </div>
              <div className={`${classes.container} ${classes.mobile}`}>
                <CarouselItemMobile item={item} classes={classes} />
              </div>
            </React.Fragment>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

const CarouselItemDesktop = ({ item, classes }) => {
  const { t } = useTranslation();
  const { accountState } = useContext(AccountContext);

  return (
    <React.Fragment>
      {/* <img
        src={item.source}
        className={`${classes.image} ${t("home.carouselStyle")}`}
        alt="backgroundImage"
      ></img> */}
      <video className={classes.image} src={accountState?.sharedData?.CAROUSEL_IMAGES} autoPlay muted></video>

      {/* <div className={classes.innerDiv}>
        <div className={classes.sectionA}>
          <span className={classes.title}>{t("home.carouselTitle")}</span>
          <span className={classes.subTitle}>{t("home.carouselSubtitle")}</span>
        </div>
        <div>
          <ItemCard
            title={"Cameron’s Specialty Coffee"}
            img={itemImage}
            description={"Organic French Roast Coffee, Whole Bean"}
            weight={"28 oz"}
            price={14.99}
          // styles={styles}
          />
        </div>
      </div> */}
    </React.Fragment>
  );
};

const CarouselItemMobile = ({ item, classes }) => {
  const { t } = useTranslation();
  const { accountState } = useContext(AccountContext);

  return (
    <React.Fragment>
      <video className={classes.image} src={accountState?.sharedData?.CAROUSEL_IMAGES} autoPlay muted></video>
      {/* <img
        src={item.source}
        className={`${classes.image} ${t("home.carouselStyle")}`}
        alt="backgroundImage"
      ></img>
      <div className={classes.innerDiv}>
        <span className={classes.title} style={{ "text-align": "center" }}>
          {t("home.carouselTitle")}
        </span>
        <div style={{ margin: "24px 0" }}>
          <ItemCard
            title={"Cameron’s Specialty Coffee"}
            img={itemImage}
            description={"Organic French Roast Coffee, Whole Bean"}
            weight={"28 oz"}
            price={14.99}
          // styles={styles}
          />
        </div>
        <span className={classes.subTitle} style={{ "text-align": "center" }}>
          {t("home.carouselSubtitle")}
        </span>
      </div> */}
    </React.Fragment>
  );
};

export default HomeCarousel;

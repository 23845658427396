import React from 'react'
import Button from '@material-ui/core/Button';
import Avatar from '@mui/material/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { GET_ACTIVE_USER, LOGOUT } from "../../../queries/auth.queries";
import {
    GET_TOTAL_LINES,
} from "../../../queries/cart.queries";
import PersonIcon from "../../common/svg/person-icon/person-icon";
const useStyles = makeStyles((theme) => ({
    paper: {
        marginRight: theme.spacing(2),
    },
}));

const UserSettingsMenu = ({ color, userData, setOpenLoginModal }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const history = useHistory();

    const [logout] = useMutation(LOGOUT, {
        refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_USER }],
    });

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const onLogout = async (e) => {
        handleClose(e);
        await logout();
        if (document.getElementById('engt')) {
            document.getElementById('engt').remove();
        }
        localStorage.removeItem("isAddressSet");
        history.push("/");
    };

    const onLogin = (e) => {
        handleClose(e);
        setOpenLoginModal(true);
    };

    const onMyAccount = (e) => {
        handleClose(e);
        if (userData && userData.activeCustomer) {
            history.push({
                pathname: "/account",
                state: {
                    page: "Orders",
                },
            });
        }
    };

    let paperContent = <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
        <MenuItem onClick={onLogin}>{t("header.Login")}</MenuItem>
    </MenuList>;

    if (userData && userData.activeCustomer) {
        paperContent = <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
            <MenuItem onClick={onMyAccount}>{t("header.myAccount")}</MenuItem>
            <MenuItem onClick={onLogout}>{t("header.LogOut")}</MenuItem>
        </MenuList>
    };

    const userElement = <div>
        <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
        >
            {!userData || !userData?.activeCustomer ?
                <i className="header__person-icon pointer" style={{ display: "flex" }}>
                    <PersonIcon stroke={color} />
                </i> :
                <Avatar sx={{ bgcolor: "rgba(21, 34, 47, 0.04)", color }}>
                    {userData?.activeCustomer?.firstName?.charAt(0)}
                    {userData?.activeCustomer?.lastName?.charAt(0)}
                </Avatar>}
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={handleClose}>
                            {paperContent}
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>;

    return userElement;
};

export default UserSettingsMenu;


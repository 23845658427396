import React, { useEffect, useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Backdrop, Fade, Modal, makeStyles } from "@material-ui/core";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Snackbar } from "@material-ui/core";

import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import {
  h6Regular,
  h6Medium,
  pointer,
} from "../../../shared/constants";
import FormInput from "../../common/form-input/form-input.component";
import {
  UPDATE_USER,
} from "../../../queries/user.queries";
import VerifyNumberContainer from "../../common/verify-number/verify-number-container.component";
import Loader from "../../common/loader/loader.component";
import { ErrorHandlingContext } from "../../../context/error-handling.context";
import Alert from "../../../shared/components/alert/alert.component";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  editName: {
    ...h6Medium,
    ...pointer,
    color: "#80BB34",
    paddingLeft: 12
  }
});

const PersonalInfo = () => {
  // methods
  const classes = useStyles();

  // use translation
  const { t } = useTranslation();

  // use state
  const [phoneValue, setPhoneValue] = useState("");
  const [fullnameValue, setFullnameValue] = useState("");
  const [open, setOpen] = useState(false);
  const [successAlertOpen, setSuccesAlertOpen] = useState(false);

  const { setErrorHandlingState } = useContext(ErrorHandlingContext);

  // queries / mutations
  const { data: userData } = useQuery(GET_ACTIVE_USER, {
    // fetchPolicy: "no-cache",
  });

  let [updateUserFullname, { loading: updateUserNameLoader, error: updateUserNameError }] = useMutation(UPDATE_USER, {
    onCompleted: () => setSuccesAlertOpen(true)
  });

  useEffect(() => {
    if (!updateUserNameError || !updateUserNameError?.message) return;

    setErrorHandlingState({
      showError: true,
      data: updateUserNameError?.message,
    });
  }, [updateUserNameError]);

  const onFullnameValueChnaged = (e) => {
    setFullnameValue(e?.target?.value);
  };

  const fullName = userData?.activeCustomer?.firstName + " " + userData?.activeCustomer?.lastName

  const onEditName = async () => {
    if (!fullnameValue || fullnameValue === fullName) return;

    try {
      const firstSpaceIndex = fullnameValue.indexOf(" ");

      // Extract first name and last name based on the first space
      const firstName = fullnameValue.slice(0, firstSpaceIndex);

      let lastName = "";
      if (fullnameValue.split(" ").length > 1) {
        lastName = fullnameValue.slice(firstSpaceIndex + 1);
      }

      await updateUserFullname({
        variables: {
          firstName,
          lastName
        },
        refetchQueries: [
          { query: GET_ACTIVE_USER },
        ],
        awaitRefetchQueries: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onEditPhone = async () => {
    if (isValidPhoneNumber(`${phoneValue}`) === false) return;

    const { activeCustomer } = userData;
    const { phoneNumber } = activeCustomer;

    if (phoneNumber === "") {
      setOpen(true);
    } else if (phoneNumber !== "" && phoneNumber !== phoneValue) {
      setOpen(true);
    } else if (phoneNumber !== "" && phoneNumber === phoneValue) {
      //user didn't change his num
    }
  };

  const disableEditPhoneButton = () => {
    const { activeCustomer } = userData;
    const { phoneNumber } = activeCustomer;

    const isPhoneChanged = phoneNumber !== "" && phoneNumber === phoneValue;

    return isValidPhoneNumber(`${phoneValue}`) === false || isPhoneChanged;
  };

  const handleClose = () => {
    setOpen(false);
  };


  // useEffect
  useEffect(() => {
    const activeCustomer = userData?.activeCustomer;
    if (!activeCustomer) return;
    const { firstName, lastName, phoneNumber } = activeCustomer;

    setPhoneValue(phoneNumber);
    if (!firstName.trim()) {
      setFullnameValue('')
      return;
    }
    setFullnameValue(firstName + " " + lastName);
  }, [userData]);

  const handleSuccesEditClose = () => {
    setSuccesAlertOpen(false);
  };

  return (
    <>
      <Typography style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
        </div>
      </Typography>
      <Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // maxWidth: 360,
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <FormInput
              name="fullnameValue"
              type="string"
              value={fullnameValue}
              handleChange={onFullnameValueChnaged}
              label={t("checkout.yourFullName")}
              border={"1px solid rgba(0, 0, 0, 0.1)"}
              maxWidth={"360"}
              margin={"0px 0px 36px 0px"}
              style={{ width: 360 }}
            />
            <span className={classes.editName} onClick={onEditName} style={{
              color:
                fullnameValue === "" || fullnameValue === fullName
                  ? "lightgray"
                  : "#80BB34"
            }}>{updateUserNameLoader ? <Loader color={"#B73232"} size={16} /> : t("common.edit")}</span>
          </div>
          <div style={{ display: "flex" }}>
            <PhoneInput
              style={{
                ...h6Regular,
                direction: "ltr",
                width: 360,
              }}
              defaultCountry="IL"
              withCountryCallingCode={true}
              international={true}
              placeholder={t("checkout.enterPhoneNumber")}
              value={phoneValue}
              onChange={setPhoneValue}
            />
            <span className={classes.editName} onClick={onEditPhone} style={{
              color:
                disableEditPhoneButton()
                  ? "lightgray"
                  : "#80BB34"
            }}>{t("common.edit")}
            </span>
          </div>
        </div>
      </Typography>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <VerifyNumberContainer
            handleClose={handleClose}
            phoneNumber={phoneValue}
            setPhoneValue={setPhoneValue}
            setOpen={setOpen}
          />
        </Fade>
      </Modal>
      <Snackbar
        open={successAlertOpen}
        autoHideDuration={2500}
        onClose={handleSuccesEditClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSuccesEditClose} severity="success" sx={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>{'Edited successfully!'}</div>
        </Alert>
      </Snackbar>
    </>
  );
};

export default PersonalInfo;

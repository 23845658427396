import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { h6Medium, pointer } from "../../../shared/constants";
import { ReactComponent as PinIcon } from "../../../assets/pin.svg";
import { useTranslation } from "react-i18next";

import "./places-autocomplete.component.css";
import { AccountContext } from "../../../context/account.context";
import configData from "../../../config/config.json";

let autoComplete;
let map;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(
  updateQuery,
  autoCompleteRef,
  mapRef,
  setAddressValues,
  setAddressChosen,
  setShowViewPort
) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      componentRestrictions: { country: "il" },
      fields: ["formatted_address", "address_components", "geometry"],
      types: ["address"],
      strictBounds: false,
    }
  );

  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(
      updateQuery,
      setAddressValues,
      setAddressChosen,
      mapRef,
      setShowViewPort
    )
  );
}

async function handlePlaceSelect(
  updateQuery,
  setAddressValues,
  setAddressChosen,
  mapRef,
  setShowViewPort
) {
  const addressObject = autoComplete.getPlace();
  setShowViewPort(true);

  const map = new window.google.maps.Map(mapRef.current, {
    center: { lat: 40.749933, lng: -73.98633 },
    zoom: 13,
    mapTypeControl: false,
  });

  autoComplete.bindTo("bounds", map);

  const marker = new window.google.maps.Marker({
    map,
    anchorPoint: new window.google.maps.Point(0, -29),
  });

  if (addressObject?.geometry?.viewport) {
    map.fitBounds(addressObject?.geometry?.viewport);
  } else {
    map.setCenter(addressObject?.geometry?.location);
    map.setZoom(17);
  }

  marker.setPosition(addressObject?.geometry?.location);
  marker.setVisible(true);

  const query = addressObject?.formatted_address;
  updateQuery(query);
  setAddressChosen(query);

  const addreassDetails = {
    city: "",
    province: "",
    postalCode: "",
  };

  for (const component of addressObject?.address_components) {
    const componentType = component.types[0];

    // eslint-disable-next-line default-case
    switch (componentType) {
      // street number
      // case "street_number": {
      //   break;
      // }

      // street name
      // case "route": {
      //   console.log(" route ", component.short_name);
      //   break;
      // }

      // postal code
      case "postal_code": {
        addreassDetails.postalCode = component.long_name;
        break;
      }

      // case "postal_code_suffix": {
      //   console.log(`postal_code_suffix ${component.long_name}`);
      //   break;
      // }

      case "locality":
        addreassDetails.city = component.long_name;
        break;
      case "administrative_area_level_1": {
        addreassDetails.province = component.short_name;
        break;
      }
      // case "country":
      //   console.log("country ", component.long_name);
      //   break;
    }
  }
  setAddressValues({
    ...addreassDetails,
    streetLine1: query,
  });
}

function SearchLocationInput({
  setAddressValues,
  styles,
  setIsVaildInput,
  value = "",
  showMapAbove = true,
}) {
  const { t } = useTranslation();
  const dir = t("css.side");

  const { accountState } = useContext(AccountContext);
  const { isAddress } = accountState;

  const [query, setQuery] = useState(value);
  const [addressChosen, setAddressChosen] = useState(value);
  const [showViewPort, setShowViewPort] = useState(false);
  const autoCompleteRef = useRef(null);
  const mapRef = useRef(null);

  const useStyles = makeStyles({
    formInput: {
      margin: styles?.margin || 0,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    if (addressChosen !== query) {
      setIsVaildInput(false);
    } else {
      setIsVaildInput(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressChosen, query]);

  useEffect(() => {
    if (isAddress !== null) {
      setQuery(isAddress);
      setAddressChosen(isAddress);
    }

    if (value !== "") {
      setQuery(value);
      setAddressChosen(value);
    }

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${accountState?.sharedData?.GOOGLE_API_KEY}&libraries=places`,
      () =>
        handleScriptLoad(
          setQuery,
          autoCompleteRef,
          mapRef,
          setAddressValues,
          setAddressChosen,
          setShowViewPort
        )
    );

    if (isAddress) {
      autoCompleteRef.current.value = isAddress;
      setTimeout(() => {
        autoCompleteRef.current.focus();
      }, 200);
    }

    if (value) {
      autoCompleteRef.current.value = value;
      setTimeout(() => {
        autoCompleteRef.current.focus();
      }, 200);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLocationError = (browserHasGeolocation, marker, pos) => {
    marker.setPosition(pos);
    marker.setTitle(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    marker.setVisible(true);
  };

  const onPinClicked = async () => {
    map = new window.google.maps.Map(mapRef.current, {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 6,
    });

    const marker = new window.google.maps.Marker({
      map,
      anchorPoint: new window.google.maps.Point(0, -29),
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const address = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&key=${accountState?.sharedData?.GOOGLE_API_KEY}`;

          fetch(address, {
            method: "GET",
          })
            .then((response) => response.json())
            .then((data) => {
              const addressObject = data.results[0];
              const query = addressObject?.formatted_address;

              setQuery(query);
              setAddressChosen(query);

              const addreassDetails = {
                city: "",
                province: "",
                postalCode: "",
              };

              if (addressObject) {
                for (const component of addressObject?.address_components) {
                  const componentType = component?.types[0];

                  // eslint-disable-next-line default-case
                  switch (componentType) {
                    // postal code
                    case "postal_code": {
                      addreassDetails.postalCode = component?.long_name;
                      break;
                    }

                    case "locality":
                      addreassDetails.city = component?.long_name;
                      break;
                    case "administrative_area_level_1": {
                      addreassDetails.province = component?.short_name;
                      break;
                    }
                  }
                }
              }
              setAddressValues({
                ...addreassDetails,
                streetLine1: query,
              });

              marker.setPosition(pos);
              marker.setVisible(true);
              map.setCenter(pos);
            });
        },
        () => {
          handleLocationError(true, marker, map.getCenter());
        }
      );
    }
  };
  const pointerPoition = dir === "ltr" ? { right: "0px" } : { left: "0px" };

  return (
    <React.Fragment>
      {showMapAbove && showViewPort ? (
        <div
          id="map"
          ref={mapRef}
          style={{
            minHeight: 250,
            width: "100%",
          }}
        ></div>
      ) : (
        ""
      )}
      <div style={{ position: "relative", direction: dir }}>
        <input
          ref={autoCompleteRef}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={t("account.mapsInput")}
          value={query}
          className={`form-input-autocomplete h6-regular  ${classes.formInput}`}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            ...pointerPoition,
            ...pointer,
          }}
          onClick={() => {
            setShowViewPort(true);
            setTimeout(() => onPinClicked(), 50);
          }}
        >
          <PinIcon />
        </div>
        {addressChosen !== query ? (
          <span style={{ ...h6Medium, color: "red" }}>
            {t("account.invalidAddress")}
          </span>
        ) : (
          ""
        )}
      </div>

      {!showMapAbove && showViewPort ? (
        <div
          id="map"
          ref={mapRef}
          style={{
            minHeight: 240,
          }}
        ></div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default SearchLocationInput;

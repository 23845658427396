import { format } from 'date-fns';

const useFormatedDate = () => {
    const getFormattedDate = (date) => {
        // Format the date as "Tuesday, Jan 2"
        return date ? format(new Date(date), 'EEEE, MMM d') : null;
    };

    return { getFormattedDate };
};

export default useFormatedDate;

import React, { useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Backdrop, Fade, Modal, makeStyles } from "@material-ui/core";
import { isValidPhoneNumber } from "react-phone-number-input";

import PersonIcon from "../../common/svg/person-icon/person-icon";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import {
  h3,
  h5Medium,
  h6Medium,
  h6Regular,
  pointer,
} from "../../../shared/constants";
import FormInput from "../../common/form-input/form-input.component";
import {
  UPDATE_USER,
  UPDATE_FULL_ADDRESS,
} from "../../../queries/user.queries";
import VerifyNumberContainer from "../../common/verify-number/verify-number-container.component";
import Loader from "../../common/loader/loader.component";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const PersonalInfo = ({ setProcesses, processes, onEditUserDetails }) => {
  // methods
  const classes = useStyles();

  // use translation
  const { t } = useTranslation();

  // use ref
  const firstUpdate = useRef(true);

  // use state
  const [phoneValue, setPhoneValue] = useState("");
  const [fullnameValue, setFullnameValue] = useState("");
  const [open, setOpen] = useState(false);
  const [closeAccordion, setCloseAccordion] = useState(false);

  // queries / mutations
  const { data: userData } = useQuery(GET_ACTIVE_USER, {
    // fetchPolicy: "no-cache",
  });

  const onFullnameValueChnaged = (e) => {
    setFullnameValue(e?.target?.value);
  };

  const onContinue = async () => {
    if (isValidPhoneNumber(`${phoneValue}`) === false) return;

    const { activeCustomer } = userData;
    // temp removed until phone number message will be implemented
    const { firstName, phoneNumber } = activeCustomer;

    // temp removed until phone number message will be implemented
    if (firstName === "") return;

    // await tempUpdatePhoneNumber();

    if (phoneNumber === "") {
      //User must validate new number
      setOpen(true);
    } else if (phoneNumber !== "" && phoneNumber !== phoneValue) {
      //user did change to a new number
      setOpen(true);
    } else if (phoneNumber !== "" && phoneNumber === phoneValue) {
      //user didn't change his num
      setProcesses();
      setCloseAccordion(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onVerify = async (bool) => {
    if (!bool) return;
    setOpen(false);

    try {
      await updateUserPhoneNumber();
      setProcesses();
      setCloseAccordion(false);
    } catch (err) {
      alert(err);
    }
  };

  const tempUpdatePhoneNumber = async () => {
    try {
      await updateUserPhoneNumber();
      setProcesses();
      setCloseAccordion(false);
    } catch (err) {
      alert(err);
    }
  };

  const { stage1Login, stage2UserDetails } = processes;

  let [updateUserFullname] = useMutation(UPDATE_USER);

  let [updateAddress] = useMutation(UPDATE_FULL_ADDRESS, {
    variables: {
      id: userData?.activeCustomer?.addresses?.find(
        (a) => a?.defaultShippingAddress
      )?.id,
      fullName: fullnameValue,
    },
    refetchQueries: [
      { query: GET_ACTIVE_USER }, // Query name
    ],
    awaitRefetchQueries: true,
  });

  let [updateUserPhoneNumber, { loading: updatePhoneLoader }] = useMutation(
    UPDATE_USER,
    {
      variables: {
        phoneNumber: phoneValue,
      },
      refetchQueries: [
        { query: GET_ACTIVE_USER }, // Query name
      ],
      awaitRefetchQueries: true,
    }
  );


  // useEffect
  useEffect(() => {
    // if (firstUpdate.current) {
    const activeCustomer = userData?.activeCustomer;
    if (!activeCustomer) return;
    const { firstName, lastName, phoneNumber } = activeCustomer;

    setPhoneValue(phoneNumber);
    if (!firstName.trim()) {
      setFullnameValue('')
      return;
    }
    setFullnameValue(firstName + " " + lastName);
    // }
  }, [userData]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const getData = setTimeout(async () => {
      try {
        const id = userData?.activeCustomer?.addresses?.find(
          (a) => a?.defaultShippingAddress
        )?.id;
        let lastName = fullnameValue.trim().split(' ');
        lastName.shift();
        lastName = lastName.join(" ");
        await updateUserFullname({
          variables: {
            firstName: fullnameValue.trim().split(' ')[0],
            lastName: lastName
          },
          refetchQueries: [
            { query: GET_ACTIVE_USER }, // Query name
          ],
          awaitRefetchQueries: true,
        });
        if (id) {
          await updateAddress();
        }
      } catch (err) {
        console.error(err);
      }
    }, 1000);

    return () => clearTimeout(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullnameValue]);

  useEffect(() => {
    if (stage1Login) {
      setCloseAccordion(stage1Login);
    }
  }, [stage1Login]);

  return (
    <Accordion
      style={{
        borderRadius: "16px",
        padding: 30,
        boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
        marginBottom: 24,
        borderBottom: "none",
      }}
      expanded={closeAccordion}
      disabled={false}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  marginRight: t("css.side") !== "ltr" ? 0 : 16,
                  marginLeft: t("css.side") !== "ltr" ? 16 : 0,
                }}
              >
                <PersonIcon stroke={"#15222F"} />
              </span>
              <span
                style={{
                  ...h3,
                  fontFamily:
                    t("css.side") !== "ltr" ? "Assistant" : "DM Serif Display",
                }}
              >
                {t("checkout.personalInfo")}
              </span>
            </div>
            {stage2UserDetails ? (
              <span
                style={{
                  ...h6Medium,
                  color: "#80BB34",
                }}
                onClick={() => {
                  setCloseAccordion(true);
                  onEditUserDetails();
                }}
              >
                {t("common.edit")}
              </span>
            ) : (
              ""
            )}
          </div>
          {!closeAccordion && (
            <div
              style={{
                ...h6Regular,
                opacity: 0.8,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>{fullnameValue}</span>
              <span style={{ marginTop: 4 }}>{phoneValue}</span>
            </div>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 360,
              width: "100%",
            }}
          >
            <FormInput
              name="fullnameValue"
              type="string"
              value={fullnameValue}
              handleChange={onFullnameValueChnaged}
              label={t("checkout.yourFullName")}
              border={"1px solid rgba(0, 0, 0, 0.1)"}
              maxWidth={"100%"}
              margin={"0px 0px 36px 0px"}
            />
            <PhoneInput
              style={{
                ...h6Regular,
                direction: "ltr",
              }}
              defaultCountry="IL"
              withCountryCallingCode={true}
              international={true}
              placeholder={t("checkout.enterPhoneNumber")}
              value={phoneValue}
              onChange={setPhoneValue}
            />
          </div>

          <button
            style={{
              height: 56,
              width: 240,
              border: "none",
              textAlign: "center",
              background:
                fullnameValue === "" ||
                  isValidPhoneNumber(`${phoneValue}`) === false
                  ? "lightgray"
                  : "#80BB34",
              borderRadius: 16,
              marginTop: 36,
              ...h5Medium,
              color: "#FFFFFF",
              ...pointer,
            }}
            onClick={onContinue}
            disabled={
              fullnameValue === "" ||
                isValidPhoneNumber(`${phoneValue}`) === false
                ? true
                : false
            }
          >
            {updatePhoneLoader ? (
              <Loader color={"#B73232"} size={30} />
            ) : (
              t("common.Continue")
            )}
          </button>
        </Typography>
      </AccordionDetails>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <VerifyNumberContainer
            handleClose={handleClose}
            onVerifyFunc={onVerify}
            phoneNumber={phoneValue}
            setPhoneValue={setPhoneValue}
          />
        </Fade>
      </Modal>
    </Accordion>
  );
};

export default PersonalInfo;

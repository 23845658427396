import React from "react";
import clsx from "clsx";
import Slider from "react-slick";

import BrokenImageIcon from "../../common/item-card/broken-image";
import "./item-page-image-galery.component.css";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from "react-i18next";

export const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};

const VariantAssets = ({ asset, mainAsset, onChange }) => {
  return (
    <div className="IPIG__image-container__thumbnail">
      <img
        key={asset?.id}
        className={clsx({
          "IPIG__image-container--image-gallery-img": true,
          selected: asset === mainAsset
        })}
        src={asset?.preview}
        alt={asset?.name}
        onClick={() => onChange(asset)}
      />
    </div>
  );
};

const ItemPageImageGalery = ({ thumbnailsList, mainImage, showThumbnailsList = true }) => {
  const [mainAsset, setMainAsset] = React.useState(mainImage);

  const { t } = useTranslation();

  React.useEffect(() => {
    setMainAsset(mainImage);
  }, [mainImage]);

  const onChange = (asset) => {
    setMainAsset(asset);
  };

  return (
    <div className="IPIG__image-container">
      {showThumbnailsList ? <div className="IPIG__image-container--image">
        {mainAsset ? (
          <img
            className="IPIG__image-container--img"
            src={mainAsset?.preview}
            alt={mainAsset?.name}
          ></img>
        ) : (
          <div className="IPIG__broken-img">
            <div style={{ width: 72, height: 72 }}>
              <BrokenImageIcon />
            </div>
            <span className="h6-regular">{t("common.brokenImg")}</span>
          </div>
        )}
      </div> :
        <div style={{ marginBottom: 30 }}>
          <Slider {...settings}>
            {thumbnailsList?.map((asset, index) => (
              <img
                key={index}
                className="IPIG__image-container--img"
                src={asset?.preview}
                alt={asset?.name}
              ></img>
            ))}
          </Slider>
        </div>}
      {showThumbnailsList && <div className="IPIG__image-container--image-gallery">
        {thumbnailsList?.map((variant) => (
          <VariantAssets
            mainAsset={mainAsset}
            key={variant.id}
            asset={variant}
            onChange={onChange}
          />
        ))}
      </div>}
    </div>
  );
};

export default ItemPageImageGalery;

import "./broken-image.css";

export default function BrokenImageIcon(props) {
  return (
    <div className="broken-image">
      <svg
        style={props.style}
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4">
          <path
            d="M49.1399 51.0002L49.1699 51.0902V51.0902C49.8346 53.5722 48.3614 56.123 45.8794 56.7877C45.8763 56.7886 45.8731 56.7894 45.8699 56.7902L23.3399 62.8502V62.8502C20.847 63.5165 18.2848 62.0409 17.6099 59.5502L9.14993 27.9902H9.14993C8.49172 25.4984 9.96426 22.9415 12.4499 22.2602L30.3299 17.4902"
            stroke="#15222F"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M60.4226 9H32.9106C31.4872 9 30.3333 10.1539 30.3333 11.5773V48.4227C30.3333 49.8461 31.4872 51 32.9106 51H60.4226C61.8461 51 63 49.8461 63 48.4227V11.5773C63 10.1539 61.8461 9 60.4226 9Z"
            stroke="#15222F"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
}

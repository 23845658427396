import React from "react";
import clsx from "clsx";

import "./item-page-responsive.component.css";

import ItemPageMerchantAndTitle from "../item-page-merchant-and-title/item-page-merchant-and-title.component";
import ItemPageImageGalery from "../item-page-image-galery/item-page-image-galery.component";
import { ReactComponent as HeartIcon } from "../../../assets/heart.svg";
import ItemPageItemDetails from "../item-page-item-details/item-page-item-details.component";
import { ItemPageAlsoLike } from "../item-page-also-like/item-page-also-like.component";
import ItemPageAddButton from "../item-page-add-button/item-page-add-button.component";

function ItemPageResponsive({ data, currentVariant, mainImage, variantsArray, setCurrentImages, thumbnailsList }) {
  return (
    <div className="IPR">
      <ItemPageMerchantAndTitle data={data} className="IPR__merchant" />
      <ItemPageImageGalery mainImage={mainImage} thumbnailsList={thumbnailsList} showThumbnailsList={false} />
      {/* <div className="IPR__price-and-heart">
        <div>
          <span className="item__details__info__weight h5-regular">
            per 1 lb
          </span>
        </div>
        <div>
          <span className="IPR__heart-icon">
            <HeartIcon />
          </span>
        </div>
      </div> */}
      {variantsArray?.length > 1 && <div className="item__variants">
        {variantsArray.map((variant) => (
          <div
            key={variant.id}
            onClick={() => {
              setCurrentImages(variant)
            }}
            className={clsx({
              "item__variants-variant": true,
              selected: variant?.id === currentVariant?.id,
            })}
          >
            <span className="item__variants-variant-img">
              <img alt={variant?.featuredAsset?.name} src={variant?.featuredAsset?.preview} />
            </span>
            <div className="item__variants-variant-info">
              <span className="item__variants-variant-name">
                {variant?.name}
              </span>
              <span className="item__variants-variant-price h4-medium">
                ₪{(variant?.priceWithTax / 100).toFixed(2)}
              </span>
            </div>
          </div>
        ))}
      </div>}
      {variantsArray?.length === 1 && <span className="item__details__info__actions--price h4-medium">
        ₪ {currentVariant?.priceWithTax ? (currentVariant?.priceWithTax / 100)?.toFixed(2) : (0).toFixed(2)}
      </span>}
      <ItemPageAddButton
        item={currentVariant}
        preview={data?.product?.assets[0]?.source}
      />
      <ItemPageItemDetails data={data} />
      <div className="IPR__cross-line"></div>
      <ItemPageAlsoLike data={data} />
    </div >
  );
}

export default ItemPageResponsive;

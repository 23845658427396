import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { HeaderContext } from "../../context/headerContext";
import { GET_ACTIVE_USER } from "../../queries/auth.queries";
import Loader from "../common/loader/loader.component";
import CheckoutProcess from "./checkout-proccess/checkout-proccess.component";
import { ReactComponent as LeftArrowIcon } from "../../assets/leftArrow.svg";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { MOVE_STATE } from "../../queries/checkout.queries";
import CheckoutTotal from "./checkout-total/checkout-total.component";

const useStyles = makeStyles({
  navigator: {
    display: "flex",
    paddingTop: 144,
    color: "#80bb34",
    display: "flex",
    alignItems: "center",
    paddingBottom: 15,
    height: 25,
    width: 100,

    "@media (max-width:940px)": {
      paddingTop: 160,
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },

  proccesses: {
    width: "63%",

    "@media (max-width:940px)": {
      width: "100%",
    },
  },

  total: {
    width: "31%",

    "@media (max-width:940px)": {
      display: "none",
    },
  },
});

const INITIAL_PROCESSES_STATE = {
  stage1Login: false,
  stage2UserDetails: false,
  stage3DeliverTo: false,
  stage4Payment: false,
  stage5DeliverySchedule: false,
  paymentURL: "",
};

const Checkout = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [moveStateToAddingItems, { loading: moveStateToAddingItemsLoader }] =
    useMutation(MOVE_STATE, {
      variables: {
        state: "AddingItems",
      },
      refetchQueries: [{ query: GET_ACTIVE_USER }],
      onCompleted: async () => history.push("/catalog"),
    });

  const goBack = async () => {
    try {
      const res = await moveStateToAddingItems();
    }
    catch (err) { }
  };

  const [processes, setProcesses] = useState(INITIAL_PROCESSES_STATE);
  const { headerState, setHeaderState } = useContext(HeaderContext);

  const [isActiveCustomer, setIsActiveCustomer] = useState(false);

  const { loading: userLoading, data: userData } = useQuery(GET_ACTIVE_USER);

  useEffect(() => {
    setHeaderState({
      ...headerState,
      color: "#15222F",
      showMainHeader: false,
    });

    return () => {
      setHeaderState({
        ...headerState,
        showMainHeader: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isActiveUser = userData?.activeCustomer;

    if (isActiveUser) {
      setIsActiveCustomer(true);
      setProcesses({
        ...processes,
        stage1Login: true,
      });
    }

    if (isActiveUser && isActiveUser?.firstName && isActiveUser?.phoneNumber) {
      setProcesses({
        ...processes,
        stage1Login: true,
        stage2UserDetails: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const onEditUserDetails = () => {
    setProcesses({
      stage1Login: true,
      stage2UserDetails: false,
      stage3DeliverTo: false,
      stage4Payment: false,
      stage5DeliverySchedule: false,
    });
  };

  const onEditAddress = () => {
    setProcesses({
      ...processes,
      stage3DeliverTo: false,
      stage4Payment: false,
      stage5DeliverySchedule: false,
    });
  };

  const onEditPayment = () => {
    setProcesses({
      ...processes,
      stage4Payment: false,
      stage5DeliverySchedule: false,
    });
  };

  const onEditDeliveryTime = () => {
    setProcesses({
      ...processes,
      stage5DeliverySchedule: false,
    });
  };

  const setReadyForLogin = () => {
    setProcesses({
      ...processes,
      stage1Login: true,
    });
  };

  const setReadyForUserDetails = () => {
    setProcesses({
      ...processes,
      stage2UserDetails: true,
    });
  };

  const setReadyForAddress = () => {
    setProcesses({
      ...processes,
      stage3DeliverTo: true,
    });
  };

  const setReadyForPayment = () => {
    setProcesses({
      ...processes,
      stage4Payment: true,
    });
  };

  const setReadyForDelivery = () => {
    setProcesses({
      ...processes,
      stage5DeliverySchedule: true,
    });
  };

  const resetProcceses = () => {
    setProcesses(INITIAL_PROCESSES_STATE);
  };

  const setPaymentURL = (paymentURL) => {
    setProcesses({ ...processes, paymentURL });
  };

  return (
    <>
      {userLoading ? (
        <div style={{ height: "60vh" }}>
          <Loader color={"#B73232"} size={100} />
        </div>
      ) : (
        <>
          <div className={classes.navigator}>
            <LeftArrowIcon onClick={goBack} />
            <span className="h5-medium pointer" onClick={goBack}>{t("common.Go back")}</span>
          </div>
          <div className={classes.container}>
            <div className={classes.proccesses}>
              <CheckoutProcess
                setReadyForUserDetails={setReadyForUserDetails}
                isActiveCustomer={isActiveCustomer}
                setReadyForLogin={setReadyForLogin}
                setReadyForAddress={setReadyForAddress}
                setReadyForPayment={setReadyForPayment}
                setReadyForDelivery={setReadyForDelivery}
                processes={processes}
                onEditAddress={onEditAddress}
                onEditPayment={onEditPayment}
                onEditDeliveryTime={onEditDeliveryTime}
                onEditUserDetails={onEditUserDetails}
                setPaymentURL={setPaymentURL}
                userData={userData}
              />
            </div>
            <div className={classes.total}>
              <CheckoutTotal processes={processes} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Checkout;

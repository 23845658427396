/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

const SET_SHIPPING_ORDER = gql`
  mutation SetShippingAddress(
    $fullName: String
    $company: String
    $streetLine1: String!
    $streetLine2: String
    $city: String
    $province: String
    $postalCode: String
    $countryCode: String!
    $phoneNumber: String
    $defaultShippingAddress: Boolean
    $defaultBillingAddress: Boolean
  ) {
    setOrderShippingAddress(
      input: {
        fullName: $fullName
        company: $company
        streetLine1: $streetLine1
        streetLine2: $streetLine2
        city: $city
        province: $province
        postalCode: $postalCode
        countryCode: $countryCode
        phoneNumber: $phoneNumber
        defaultShippingAddress: $defaultShippingAddress
        defaultBillingAddress: $defaultBillingAddress
      }
    ) {
      ...Cart
      ... on Order {
        shippingAddress {
          ...OrderAddress
          __typename
        }
        __typename
      }
      ...ErrorResult
      __typename
    }
  }
  fragment Cart on Order {
    id
    code
    state
    active
    lines {
      id
      featuredAsset {
        ...Asset
        __typename
      }
      unitPrice
      unitPriceWithTax
      quantity
      linePriceWithTax
      discountedLinePriceWithTax
      productVariant {
        id
        name
        __typename
      }
      discounts {
        amount
        amountWithTax
        description
        adjustmentSource
        type
        __typename
      }
      __typename
    }
    totalQuantity
    subTotal
    subTotalWithTax
    total
    totalWithTax
    shipping
    shippingWithTax
    shippingLines {
      priceWithTax
      shippingMethod {
        id
        code
        name
        description
        __typename
      }
      __typename
    }
    discounts {
      amount
      amountWithTax
      description
      adjustmentSource
      type
      __typename
    }
    __typename
  }
  fragment Asset on Asset {
    id
    width
    height
    name
    preview
    focalPoint {
      x
      y
      __typename
    }
    __typename
  }
  fragment OrderAddress on OrderAddress {
    fullName
    company
    streetLine1
    streetLine2
    city
    province
    postalCode
    country
    phoneNumber
    __typename
  }
  fragment ErrorResult on ErrorResult {
    errorCode
    message
    __typename
  }
`;

const GET_AVAILABLE_COUNTRIES = gql`
  query GetAvailableCountries {
    availableCountries {
      ...Country
      __typename
    }
  }
  fragment Country on Country {
    id
    code
    name
    enabled
    __typename
  }
`;

const ADD_PAYMENT = gql`
  mutation AddPayment($method: String!, $metadata: JSON!) {
    addPaymentToOrder(input: { method: $method, metadata: $metadata }) {
      ...Cart
      ...ErrorResult
      __typename
    }
  }
  fragment Cart on Order {
    id
    code
    state
    active
    lines {
      id
      featuredAsset {
        ...Asset
        __typename
      }
      unitPrice
      unitPriceWithTax
      quantity
      linePriceWithTax
      discountedLinePriceWithTax
      productVariant {
        id
        name
        __typename
      }
      discounts {
        amount
        amountWithTax
        description
        adjustmentSource
        type
        __typename
      }
      __typename
    }
    totalQuantity
    subTotal
    subTotalWithTax
    total
    totalWithTax
    shipping
    shippingWithTax
    shippingLines {
      priceWithTax
      shippingMethod {
        id
        code
        name
        description
        __typename
      }
      __typename
    }
    discounts {
      amount
      amountWithTax
      description
      adjustmentSource
      type
      __typename
    }
    __typename
  }
  fragment Asset on Asset {
    id
    width
    height
    name
    preview
    focalPoint {
      x
      y
      __typename
    }
    __typename
  }
  fragment ErrorResult on ErrorResult {
    errorCode
    message
    __typename
  }
`;

//AddingItems
//ArrangingPayment

const MOVE_STATE = gql`
  mutation transitionOrderToState($state: String!) {
    transitionOrderToState(state: $state) {
      ... on Order {
        state: state
        paymentUrl
      }
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
      }
      __typename
    }
  }
`;

const MOVE_STATE_TO_FULLFILING_ITEMS = gql`
  mutation TransitionToFulfillingItems {
    transitionOrderToState(state: "FulfillingItems") {
      ...Cart
      ...ErrorResult
      __typename
    }
  }
  fragment Cart on Order {
    id
    code
    state
    active
    lines {
      id
      featuredAsset {
        ...Asset
        __typename
      }
      unitPrice
      unitPriceWithTax
      quantity
      linePriceWithTax
      discountedLinePriceWithTax
      productVariant {
        id
        name
        __typename
      }
      discounts {
        amount
        amountWithTax
        description
        adjustmentSource
        type
        __typename
      }
      __typename
    }
    totalQuantity
    subTotal
    subTotalWithTax
    total
    totalWithTax
    shipping
    shippingWithTax
    shippingLines {
      priceWithTax
      shippingMethod {
        id
        code
        name
        description
        __typename
      }
      __typename
    }
    discounts {
      amount
      amountWithTax
      description
      adjustmentSource
      type
      __typename
    }
    __typename
  }
  fragment Asset on Asset {
    id
    width
    height
    name
    preview
    focalPoint {
      x
      y
      __typename
    }
    __typename
  }
  fragment ErrorResult on ErrorResult {
    errorCode
    message
    __typename
  }
`;
export {
  SET_SHIPPING_ORDER,
  GET_AVAILABLE_COUNTRIES,
  ADD_PAYMENT,
  MOVE_STATE,
  MOVE_STATE_TO_FULLFILING_ITEMS,
};

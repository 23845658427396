import React from "react";
import {
  h4Medium,
  h5Regular,
  h6Medium,
  h7Regular,
} from "../../../shared/constants";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import { ReactComponent as DeliveryTruckIcon } from "../../../assets/deliveryTruck.svg";
import { ReactComponent as BasketCheckIcon } from "../../../assets/basketCheck.svg";
import { ReactComponent as HealthIcon } from "../../../assets/health.svg";
import { ReactComponent as NavigationIcon } from "../../../assets/navigation.svg";

const iconsContainer = {
  basketCheck: <BasketCheckIcon />,
  helth: <HealthIcon />,
  navigation: <NavigationIcon />,
  deliveryTruck: <DeliveryTruckIcon />,
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: "120px 0",
    gap: 40
  },
  innerDiv: {
    height: 160,
    width: 310,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    "@media (max-width:940px)": {
      width: 165,
    },
    "&:last-child": {
      marginRight: 0,
    }
  },
  svgContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 32,
    marginBottom: 16,
    alignItems: "center",
    "@media (max-width:940px)": {
      gap: 16,
    },
  },
  title: {
    ...h4Medium,
    opacity: 0.72,
    "@media (max-width:940px)": {
      ...h6Medium,
    },
  },
  description: {
    opacity: 0.64,
    ...h5Regular,
    "@media (max-width:940px)": {
      ...h7Regular,
      marginTop: 8,
    },
  },
});

const SixthAboutSect = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {/* <div className={classes.background}></div> */}
      {["basketCheck", "helth", "navigation", "deliveryTruck"].map(
        (text, index) => {
          return (
            <div className={classes.innerDiv} key={text}>
              <div className={classes.svgContainer}>
                {iconsContainer[text]}
                <span className={classes.title}>
                  {t(`home.titlesContainer.${text}`)}
                </span>
              </div>
              <span className={classes.description}>
                {t(`home.descsContainer.${text}`)}
              </span>
            </div>
          );
        }
      )}
    </div>
  );
};

export default SixthAboutSect;

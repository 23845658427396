import { useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_MERCHANT_LOGOS } from "../../../queries/queries";
import { FilterContext } from '../../../context/filterContext';

import "./item-page-merchant-and-title.component.css";

const ItemPageMerchantAndTitle = ({ data }) => {
  const { data: merchantsData } = useQuery(GET_MERCHANT_LOGOS);
  const { searchState, setSearchState } = useContext(FilterContext);
  const [merchantSlug, setMerchantSlug] = useState(null);
  const history = useHistory();

  React.useEffect(() => {
    const merchant = merchantsData?.search?.items?.filter((item) => {
      return item?.productId === data?.product?.id;
    });
    if (!merchant) return;

    setMerchantSlug(merchant[0]?.merchantSlug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantsData]);

  const goToMerchantPage = () => {
    let slug = merchantSlug;

    if (!slug) {
      slug = getMerchantSlug();
    }

    const collection = data?.product?.collections?.find(collection => collection?.children?.length);
    const facet = data?.product?.facetValues[0];
    const itemName = data?.product?.name;

    setSearchState({
      ...searchState,
      collectionId: collection,
      facetValueIds: [facet],
      previousPage: history.location.pathname,
      itemName
    })

    history.push({
      pathname: `/supplier/${slug}`,
    });
  }

  const getMerchantSlug = () => {
    const merchant = merchantsData?.search?.items?.filter((item) => {
      return item?.productId === data?.product?.id;
    });
    return merchant[0]?.merchantSlug;
  };
  return (
    <React.Fragment>
      <div className="IPMAT__image-section">
        <div className="IPMAT__merchant-img">
          <img
            src={data?.product?.merchant?.featuredAsset?.source}
            alt={data?.product?.merchant?.name}
          ></img>
        </div>
        <div
          className="IPMAT__supplier h7-regular"
          onClick={goToMerchantPage}
        >
          {data?.product?.merchant?.name}
        </div>
      </div>
      <span className="IPMAT__title h2">{data?.product?.name}</span>
    </React.Fragment>
  );
};

export default ItemPageMerchantAndTitle;

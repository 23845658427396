import React, { useContext } from "react";

import { useQuery } from "@apollo/client";

import "./catalog-filters.css";

import { FilterContext, DEFAULT_SORT } from "../../../context/filterContext";
import CatalogSubFilters from "../catalog-sub-filters/catalog-sub-filters.component";

import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import CatalogFiltersTags from "../catalog-filters-tags/catalog-filters-tags.component";
import { useTranslation } from "react-i18next";
import { GET_ITEMS_WITH_FILTERS } from "../../../queries/queries";
import { useLocation } from "react-router-dom";

const _ = require("lodash");


const CatalogFilters = ({ isResponsive, toggleDrawer }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { searchState, setSearchState } = useContext(FilterContext);

  const { error, data } = useQuery(GET_ITEMS_WITH_FILTERS, {
    variables: {
      term: "",
      facetValueIds: [],
      collectionId: null,
      groupByProduct: !searchState?.term?.trim(),
    },
  });

  if (error) return `Error! ${error.message}`;

  const filters = _(data?.search?.facetValues)
    .groupBy("facetValue.facet.name")
    .mapValues((v) => _.sortBy(v, "facetValue.name"))
    .toPairs()
    .sortBy(0)
    .fromPairs()
    .value();

  const { facetValueIds } = searchState;

  const updateFacetsValues = (fv) => {
    const { __typename } = fv;

    if (__typename === "FacetValue") {
      const facetValueId = fv;

      setSearchState({
        ...searchState,
        facetValueIds: [facetValueId],
        collectionId: fv?.category,
        term: "",
        sort: DEFAULT_SORT,
        previousPage: location?.pathname,
      });
    } else if (__typename === "Collection") {
      setSearchState({
        ...searchState,
        facetValueIds: [],
        collectionId: fv,
        facetFaluesPerCollectionInit: true,
        term: "",
        previousPage: location?.pathname
      });
    }
  };

  const removeFacetsValues = (fv) => {
    const { __typename } = fv;

    if (__typename === "FacetValue") {
      const facetValueId = fv.id;
      setSearchState({
        ...searchState,
        term: "",
        facetValueIds: facetValueIds.filter((fv) => {
          return fv.id !== facetValueId;
        }),
        previousPage: location?.pathname
      });
    } else if (__typename === "Collection") {
      setSearchState({
        ...searchState,
        facetValueIds: [],
        collectionId: null,
        term: "",
        previousPage: location?.pathname
      });
    }
  };

  return (
    <div className="catalog-filters">
      {isResponsive ? (
        <div>
          <div className="h5-medium catalog-filters__title">
            <span>{t("catalog.Category")}</span>
            <RemoveIcon onClick={toggleDrawer("bottom", false)} />
          </div>
          <CatalogFiltersTags />
        </div>
      ) : (
        <div className="h4-medium catalog-filters__title">
          {t("catalog.Category")}
        </div>
      )}

      {/* <CatalogCollectionsFilters
        updateFacetsValues={updateFacetsValues}
        removeFacetsValues={removeFacetsValues}
        collectionId={collectionId}
      /> */}
      <CatalogSubFilters
        updateFacetsValues={updateFacetsValues}
        removeFacetsValues={removeFacetsValues}
        filters={filters}
      />
    </div>
  );
};

export default CatalogFilters;

export const sortDropdownOptions = [
    {
        label: "Name (A to Z)",
        sort: {
            name: 'ASC'
        },
    },
    {
        label: "Name (Z to A)",
        sort: {
            name: 'DESC'
        },
    },
    {
        label: "Price (Low to High)",
        sort: {
            price: 'ASC'
        },
    },
    {
        label: "Price (High to Low)",
        sort: {
            price: 'DESC'
        },
    }
];
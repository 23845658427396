import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { h2, h3, h5Medium, h5Regular } from "../../../shared/constants";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./second-catalog-sect.css";

const imageSource = require("./catalogImage.png").default;

const useStyles = makeStyles({
  container: {
    width: "100%",
    maxHeight: 770,
    height: "100%",
    display: "flex",
    "@media (max-width:940px)": {
      justifyContent: "center",
      height: 360,
    },
  },
  descriptionDiv: {
    width: "40%",
    display: "flex",
    alignItems: "center",
    background: "#FFFFFF",
    "@media (max-width:940px)": {
      width: "100%",
    },
  },
  descriptionDivContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 40px",
    "@media (max-width:940px)": {
      margin: "0px 16px",
    },
  },
  title: {
    ...h2,
    "@media (max-width:940px)": {
      ...h3,
    },
  },
  description: {
    ...h5Regular,
    opacity: 0.64,
    marginTop: 32,
  },
  imageDiv: {
    width: "60%",
    maxHeight: 770,
    "@media (max-width:940px)": {
      display: "none",
    },
  },
  image: {
    width: "100%",
    objectFit: "cover",
    height: "100%",
    imageRendering: "pixelated",
  },
  button: {
    ...h5Medium,
    cursor: "pointer",
    width: 310,
    height: 56,
    background: "#80BB34",
    borderRadius: 16,
    marginTop: 48,
    border: "none",
    color: "#FFFFFF",
    "@media (max-width:940px)": {
      width: "100%",
    },
  },
});

const SecondCatalogSect = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const navigateToCatlog = () => {
    history.push("/catalog");
  };

  return (
    <div className={classes.container}>
      <div className={classes.descriptionDiv}>
        <div className={classes.descriptionDivContainer}>
          <span className={classes.title}>{t("home.catalogSectionTitle")}</span>
          <span className={classes.description}>
            {t("home.catalogDescription")}
          </span>
          <button className={classes.button} onClick={navigateToCatlog}>
            {t("home.Catalog")}
          </button>
        </div>
      </div>
      <div className={`${classes.imageDiv} ${t("home.catalogSectionStyle")}`}>
        <img src={imageSource} className={classes.image} alt="fruits"></img>
      </div>
    </div>
  );
};

export default SecondCatalogSect;

import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { h4Medium, h5Medium } from "../../../shared/constants";

const useStyles = makeStyles({
  container: {
    background: "#FFFFFF",
    width: "100%",
    padding: "48px 0",
    borderRadius: 32,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    margin: "0 auto",
  },
  title: {
    ...h4Medium,
    "@media (max-width:940px)": {
      ...h5Medium,
    },
  },
});

const PaymentError = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div style={{ paddingTop: 144 }}></div>
      <div className={classes.container}>
        <span className={classes.title}>{t("checkout.paymentError")}</span>
      </div>
    </>
  );
};

export default PaymentError;

import React, { useContext, useRef, useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fade, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useLocation } from "react-router-dom";

import "./Header.css";
import DeliveryTruckIcon from "../common/svg/delivery-truck-icon/delivery-truck-icon";
import BasketIcon from "../common/svg/basket/basket";
import { DEFAULT_SORT, FilterContext } from "../../context/filterContext";
import HeaderResponsive from "./header-responsive";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { ReactComponent as HeLogo } from "../../assets/logo_he.svg";
import { ReactComponent as EnLogo } from "../../assets/logo_en.svg";
import CategoriesContainer from "./categories-container/categories-container";
import CartIndicator from "../cart/cart-indicator/cart-indicator.component";
import { HeaderContext } from "../../context/headerContext";
import { CartContext } from "../../context/cartContext";
import { SwipeableDrawer } from "@material-ui/core";
import CartDrawer from "../cart/cart-drawer/cart-drawer.component";
import { AccountContext } from "../../context/account.context";
import HeaderAccount from "./header-account/header-account.component";
import HeaderAddress from "./header-address/header-address.component";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USER } from "../../queries/auth.queries";
import { pointer } from "../../shared/constants";
import DeliveryAddress from "../common/delivery-address/delivery-address.component";
import WithErrorHandling from "../../shared/components/with-error-handling/with-error-handling.component";
import SearchDropdown from "../../shared/components/search-dropdown/search-dropdown.jsx";
import UserSettingsMenu from "./user-settings-menu/user-settings-menu.jsx";
import HeaderDeliverTo from "./header-deliver-to/header-deliver-to.jsx";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    position: (props) => (props.toggleFixed ? "fixed" : "absolute"),
    backgroundColor: (props) => props.toggleFixed && "rgba(245,247,249,0.5)",
    backdropFilter: (props) => props.toggleFixed && "blur(15px)",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  scrolled: {
    padding: "24px 40px",
  },
}));

function Header() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [boxInput, setBoxInput] = useState("");
  const firstUpdate = useRef(true);
  const [scrolled, setScrolled] = useState(false);

  const { searchState, setSearchState } = useContext(FilterContext);
  const { headerState } = useContext(HeaderContext);
  const { cartState, setCartState } = useContext(CartContext);
  const { accountState, setAccountState } = useContext(AccountContext);

  const { isUser, user, isDeliveryModalAddress, isLoginModalOpen } =
    accountState;

  const { isCartOpen } = cartState;

  const { color, showCategories, toggleFixed, showMainHeader } = headerState;

  const { data: userData } = useQuery(GET_ACTIVE_USER);

  const classes = useStyles({ toggleFixed });
  const history = useHistory();

  const updateFilters = (e) => {
    setSearchState({
      ...searchState,
      facetValueIds: [],
      collectionId: e,
      facetFaluesPerCollectionInit: true,
      term: "",
      previousPage: location?.pathname,
    });
  };

  const onSelect = (e) => {
    history.push({
      pathname: "/item/" + e.slug,
    });
  };

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    history.push({
      pathname: "/catalog",
    });
    const getData = setTimeout(() => {
      setSearchState({
        ...searchState,
        isInit: true,
        facetFaluesPerCollectionInit: true,
        facetValuesDefaultFilters: [],
        facetFaluesDefaultPerCollection: [],
        facetValueIdsPerCollection: [],
        facetValueIds: [],
        collectionId: null,
        term: boxInput.trim(),
        previousPage: location?.pathname,
      });
    }, 1000);

    return () => clearTimeout(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxInput]);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const dropdownRef = useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleCart = () => {
    setCartState({
      ...cartState,
      isCartOpen: !isCartOpen,
    });
  };

  const [addressModal, setaddressModal] = useState(false);

  const handleOpen = () => {
    if (userData && userData.activeCustomer) {
      history.push({
        pathname: "/account",
        state: {
          // location state
          page: "Orders",
        },
      });
      return;
    }
    setAccountState({
      ...accountState,
      isLoginModalOpen: true,
    });
  };

  const handleOpenAddressModal = () => {
    if (isUser && user) {
      setaddressModal(true);
    } else {
      setAccountState({
        ...accountState,
        isDeliveryModalAddress: true,
      });
    }
  };

  const handleCloseAddressModal = () => {
    if (isUser && user) {
      setaddressModal(false);
    } else {
      setAccountState({
        ...accountState,
        isDeliveryModalAddress: false,
      });
    }
  };

  const handleClose = () => {
    setAccountState({
      ...accountState,
      isLoginModalOpen: false,
    });
  };

  const changeLang = () => {
    let lang = localStorage.getItem("lang");
    let isEnglish = true;
    if (lang !== null && lang === "he") {
      isEnglish = false;
    }
    if (isEnglish) {
      i18n.changeLanguage("he");
      localStorage.setItem("lang", "he");
      adjustMetaHeaderLogo("he");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
      adjustMetaHeaderLogo("en");
    }
  };

  const adjustMetaHeaderLogo = (lang) => {
    let link =
      document.querySelector('link[rel="shortcut icon"]') ||
      document.querySelector('link[rel="icon"]');

    if (!link) {
      link = document.createElement("link");
      link.id = "favicon";
      link.rel = "shortcut icon";
      document.head.appendChild(link);
    }

    link.href = `images/${lang}/favicon.ico`;
  };

  let truckIconStyle = t("header.truckIconStyle");

  const onEnter = (term) => {
    if (!term) return;

    setSearchState({
      ...searchState,
      facetValueIds: [],
      collectionId: null,
      term,
      sort: DEFAULT_SORT,
      previousPage: location?.pathname,
    });
  };

  return (
    <React.Fragment>
      <HeaderResponsive
        isCartOpen={isCartOpen}
        toggleCart={toggleCart}
        color={color}
        className="header-res"
        handleOpenAddressModal={handleOpenAddressModal}
      ></HeaderResponsive>
      {showMainHeader ? (
        <header
          className={clsx("header", classes.header, {
            [classes.scrolled]: scrolled,
          })}
        >
          <span
            className="header__logo pointer"
            style={{ color }}
            onClick={() => {
              history.push("/");
            }}
          >
            {t("graphql.languageCode") === "en" ? (
              <EnLogo width={scrolled ? 61 : 80} height={scrolled ? 56 : 72} />
            ) : (
              <HeLogo width={scrolled ? 61 : 80} height={scrolled ? 56 : 72} />
            )}
          </span>
          <div className="header-left-container">
            <div className="header__search__select__container">
              <div style={{ width: showCategories ? "65%" : "100%" }}>
                <SearchDropdown onSelect={onSelect} onEnter={onEnter} />
              </div>

              {showCategories ? (
                <div
                  className={`header__categories-dropdown ${t(
                    "header.categoriesDDStyle"
                  )}`}
                  onClick={(e) => {
                    setIsOpen(!isOpen);
                  }}
                  ref={dropdownRef}
                >
                  <span className="header__categories-dropdown-title">
                    {t("header.categories")}
                  </span>
                  <ArrowIcon />

                  <CategoriesContainer
                    className={isOpen ? "CC__show" : "CC__hide"}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    updateFilters={updateFilters}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="header__management">
              <div className="header__delivery-address">
                <span
                  className="header__language pointer"
                  style={{ color }}
                  onClick={() => {
                    changeLang();
                  }}
                >
                  {t("header.language")}
                </span>
                {!userData?.activeCustomer ? (
                  <div
                    className="HM__delivery-address"
                    onClick={handleOpenAddressModal}
                  >
                    <i className={`${truckIconStyle}`}>
                      <DeliveryTruckIcon stroke={color} />
                    </i>
                    <span className="h6-regular" style={{ color, ...pointer }}>
                      {t("header.deliveryAddress")}
                    </span>
                  </div>
                ) : (
                  <HeaderDeliverTo
                    userData={userData}
                    color={color}
                    handleOpenAddressModal={handleOpenAddressModal}
                  />
                )}
                <UserSettingsMenu
                  color={color}
                  userData={userData}
                  setOpenLoginModal={(isOpen) => {
                    setAccountState({
                      ...accountState,
                      isLoginModalOpen: isOpen,
                    });
                  }}
                />
                <CartIndicator onClick={toggleCart} iconColor={color} />

                <Modal
                  className={classes.modal}
                  open={addressModal}
                  onClose={handleCloseAddressModal}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={addressModal}>
                    <HeaderAddress
                      handleCloseAddressModal={handleCloseAddressModal}
                    />
                  </Fade>
                </Modal>

                <Modal
                  className={classes.modal}
                  open={isDeliveryModalAddress}
                  onClose={handleCloseAddressModal}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={isDeliveryModalAddress}>
                    <DeliveryAddress />
                  </Fade>
                </Modal>

                <Modal
                  className={classes.modal}
                  open={isLoginModalOpen}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={isLoginModalOpen}>
                    <HeaderAccount handleClose={handleClose} />
                  </Fade>
                </Modal>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header className={clsx("header", { [classes.scrolled]: scrolled })}>
          <span
            className="header__logo pointer"
            style={{ color }}
            onClick={() => {
              history.push("/");
            }}
          >
            {t("graphql.languageCode") === "en" ? (
              <EnLogo width={80} height={72} />
            ) : (
              <HeLogo width={80} height={72} />
            )}
          </span>
        </header>
      )}
      <SwipeableDrawer
        anchor={t("css.side") === "ltr" ? "right" : "left"}
        open={isCartOpen}
        onClose={toggleCart}
        onOpen={toggleCart}
      >
        <CartDrawer toggleCart={toggleCart} />
      </SwipeableDrawer>
    </React.Fragment>
  );
}

export default WithErrorHandling(Header);

import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import { h4Medium, h5Medium, pointer } from "../../../shared/constants";
import { CREATE_ADDRESS, GET_ACTIVE_USER } from "../../../queries/auth.queries";
import Loader from "../../common/loader/loader.component";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import AddressForm from "./address-form.component";
import { isValidPhoneNumber } from "react-phone-number-input";

const useStyles = makeStyles({
  button: {
    height: 56,
    width: "100%",
    border: "none",
    textAlign: "center",
    background: "#80BB34",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },
  formContainer: {
    width: 640,
    maxHeight: 760,
    height: "auto",
    boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
    borderRadius: 32,
    background: "#FFFFFF",
    padding: 48,
    overflow: "auto",
  },
  header: {
    display: "flex",
    height: 56,
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",

    "@media (max-width:940px)": {
      height: 32,
    },
  },

  headerTitles: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    ...h4Medium,
  },
});

const AddAddressForm = ({ setIsEditMode, fetchLazy, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dir = t("css.side");

  const [isDisabled, setIsDisabled] = useState(true);

  const [addressDetails, setAddressDetails] = useState({
    streetLineTwo: "",
    phoneNumber: "",
    fullname: "",
    comments: "",
    defaultAddress: false,
    streetLine1: "",
  });

  const [addressValues, setAddressValues] = useState({
    city: "",
    province: "",
    postalCode: "",
    streetLine1: "",
  });

  const [isVaildInput, setIsVaildInput] = useState(false);

  const [isFirstAddress, setIsFirstAddress] = useState(true);

  const { data: userData } = useQuery(GET_ACTIVE_USER);

  useEffect(() => {
    if (userData) {
      const userAddresses = userData?.activeCustomer?.addresses;
      if (userAddresses.length > 1) {
        setIsFirstAddress(false);
      }
    }
  }, [userData]);

  let [createAddress, { loading: createAddressLoading }] = useMutation(
    CREATE_ADDRESS,
    {
      variables: {
        fullName: addressDetails.fullname,
        phoneNumber: addressDetails.phoneNumber,
        streetLine1: addressValues.streetLine1.trim() ? addressValues.streetLine1 : addressDetails.streetLine1,
        streetLine2: addressDetails.streetLineTwo,
        city: addressValues.city,
        province: addressValues.province,
        postalCode: addressValues.postalCode,
        countryCode: "IL",
        defaultShippingAddress: addressDetails.defaultAddress,
        defaultBillingAddress: addressDetails.defaultAddress,
      },
      refetchQueries: [
        { query: GET_ACTIVE_USER }, // Query name
      ],
      awaitRefetchQueries: true,
    }
  );

  useEffect(() => {
    const isValidPhone = isValidPhoneNumber(addressDetails.phoneNumber);
    const isValidAddress = addressDetails.streetLine1 !== "";
    if (!isValidPhone || !isValidAddress) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDetails.streetLine1, addressDetails.phoneNumber, addressValues]);

  if (createAddressLoading) return <Loader color={"#B73232"} size={70} />;

  const handleChange = (e) => {
    const { value, name } = e.target;

    setAddressDetails({ ...addressDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (isDisabled) return;

    try {
      const addressCreated = await createAddress();
      setIsEditMode(false);
      fetchLazy();
      if (addressCreated && addressCreated?.createCustomerAddress) {
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <React.Fragment>
      <form className={classes.formContainer} style={{ direction: dir }}>
        <div className={classes.header}>
          <div className={classes.headerTitles}>
            <span>{t("account.addDeliveryAddress")}</span>
          </div>
          <div className="pointer" onClick={handleClose}>
            <RemoveIcon
              className={classes.closeIcon}
              style={{ height: 24, width: 24 }}
            />
          </div>
        </div>
        <AddressForm
          addressDetails={addressDetails}
          handleChange={handleChange}
          setAddressDetails={setAddressDetails}
          defaultAddress={addressDetails.defaultAddress}
          setAddressValues={setAddressValues}
          setIsVaildInput={setIsVaildInput}
          isFirstAddress={isFirstAddress}
        />
        <div className="buttons">
          <button
            type="button"
            className={classes.button}
            disabled={isDisabled}
            style={{
              background: isDisabled ? "grey" : "#80BB34",
            }}
            onClick={handleSubmit}
          >
            {t("common.Save")}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default AddAddressForm;

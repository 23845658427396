import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USER } from "../queries/auth.queries";

// useReducer//
const initialState = {
  isLoginModalOpen: false,
  isUser: false,
  user: {},
  isAddress: null,
  isDeliveryModalAddress: false,
  sharedData: {},
};

const AccountContext = React.createContext(initialState);

const AccountContextProvider = ({ children }) => {
  const [accountState, setAccountState] = useState(initialState);

  const { data } = useQuery(GET_ACTIVE_USER);

  useEffect(() => {
    if (data) {
      setAccountState({
        ...accountState,
        isUser: !!data.activeCustomer,
        user: data.activeCustomer,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <AccountContext.Provider value={{ accountState, setAccountState }}>
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountContextProvider };

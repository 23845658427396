import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import Radio from "@mui/material/Radio";
import { makeStyles } from "@material-ui/core";

import { ReactComponent as StarIcon } from "../../../assets/star.svg";
import { h5Medium, h6Regular } from "../../../shared/constants";

const useStyles = makeStyles({
  addressDetails: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
  },

  addressDetailsBody: {
    display: "flex",
    flexDirection: "column",
  },
});

const DefaultAddress = ({ setAddressForShipping }) => {
  const classes = useStyles();

  const { data } = useQuery(GET_ACTIVE_USER);

  const [chosenAddress, setChosenAddress] = useState(null);

  useEffect(() => {
    if (data) {
      const defaultAddress = data?.activeCustomer?.addresses?.filter(
        (a) => a.defaultShippingAddress
      );

      if (defaultAddress) {
        setChosenAddress(defaultAddress[0]);
        setStateForShipping(defaultAddress[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const setStateForShipping = (item) => {
    setAddressForShipping({
      fullName: data?.activeCustomer?.firstName,
      streetLine1: item?.streetLine1,
      streetLine2: item?.streetLine2,
      city: item?.streetLine2,
      postalCode: item?.postalCode,
      countryCode: "IL",
      phoneNumber: item?.phoneNumber,
      defaultShippingAddress: item?.defaultShippingAddress,
      defaultBillingAddress: item?.defaultBillingAddress,
      idForUpdate: item?.id,
    });
  };

  const controlProps = (idx, item) => ({
    checked: chosenAddress.id === idx,
    onChange: () => {
      setChosenAddress(item);
      setStateForShipping(item);
    },
    value: idx,
    name: "address-chosen",
    inputProps: { "aria-label": idx },
  });

  return chosenAddress ? (
    <div className={classes.addressDetails}>
      <Radio
        {...controlProps(chosenAddress?.id, chosenAddress)}
        color="success"
      />
      {chosenAddress?.defaultShippingAddress === true ? (
        <div style={{ marginRight: 8 }}>
          <StarIcon />
        </div>
      ) : (
        ""
      )}
      <div className={classes.addressDetailsBody}>
        <span style={{ ...h6Regular, opacity: 0.8 }}>
          {chosenAddress?.streetLine1}
        </span>
      </div>
    </div>
  ) : (
    <span
      style={{
        ...h5Medium,
        opacity: 0.8,
        display: "flex",
        justifyContent: "center",
      }}
    >
      No Default Address
    </span>
  );
};

export default DefaultAddress;

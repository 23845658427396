import React, { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

// import HomeCarousel from '../home/home-carousel/home-carousel.component';
import FirstImageSect from "./first-image-sect/first-image-sect";
import SecondCatalogSect from "./second-catalog-sect/second-catalog-sect";
import SixthAboutSect from "./sixth-about-sect/sixth-about-sect";
import OurSuppliers from "../common/our-suppliers/our-suppliers.component";
import HomeSeasonalProducts from "../home/home-seasonal-products/home-seasonal-products.component";
import { HeaderContext } from "../../context/headerContext";

export default function HomePage() {
  const { headerState, setHeaderState } = useContext(HeaderContext);
  const location = useLocation();
  const ourSuppliersRef = useRef(null);

  useEffect(() => {
    setHeaderState({
      ...headerState,
      color: "#FFFFFF",
      showCategories: false,
      showMainHeader: true,
      toggleFixed: false,
    });

    return () => {
      setHeaderState({
        ...headerState,
        color: "#15222F",
        showCategories: true,
        showMainHeader: true,
        toggleFixed: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setHeaderState({
          ...headerState,
          color: "#15222F",
          showCategories: true,
          showMainHeader: true,
          toggleFixed: true,
        });
      } else {
        setHeaderState({
          ...headerState,
          color: "#FFFFFF",
          showCategories: false,
          showMainHeader: true,
          toggleFixed: false,
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    console.log('location.hash ', location.hash);
    console.log('ourSuppliersRef.curren ', ourSuppliersRef);
    // Scroll to Our Suppliers section if the URL contains #our-suppliers
    if (location.hash === "#our-suppliers" && ourSuppliersRef.current) {
      ourSuppliersRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div style={{ maxWidth: 1680, width: "100%", overflow: "hidden" }}>
      <FirstImageSect />
      <SixthAboutSect />
      <HomeSeasonalProducts />
      {/* hide it for now until we'll have a relevant engine for this  */}
      {/* <FourthPopularSect /> */}
      {/* <HomeCarousel /> */}
      <OurSuppliers ref={ourSuppliersRef} />
    </div>
  );
}

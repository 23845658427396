import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FilterContext } from "../../../context/filterContext";
import { ReactComponent as BreadCrumbsArrow } from "../../../assets/rightArrow.svg";

const ItemPageBreadcrumbs = ({ collection, facet, itemName }) => {
  const { searchState, setSearchState } = useContext(FilterContext);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  if (!collection || !facet || !itemName) {
    return <div style={{ padding: "140px 0px 56px 0px" }}></div>;
  }

  const updateFilters = (typename = null) => {
    if (!typename) {
      setSearchState({
        isInit: true,
        facetFaluesPerCollectionInit: true,
        facetValuesDefaultFilters: [],
        facetFaluesDefaultPerCollection: [],
        facetValueIdsPerCollection: [],
        facetValueIds: [],
        collectionId: null,
        term: "",
        previousPage: location?.pathname,
      });
    }

    if (typename === "facet") {
      setSearchState({
        ...searchState,
        term: "",
        collectionId: collection,
        facetValueIds: [facet],
        previousPage: location?.pathname,
      });
    }

    if (typename === "collection") {
      setSearchState({
        ...searchState,
        facetValueIds: [],
        collectionId: collection,
        term: "",
        previousPage: location?.pathname,
      });
    }
    history.push("/catalog");
  };

  const collectionBreadcrumb = (
    <div
      onClick={() => updateFilters("collection")}
      style={{ display: "flex", gap: 16, alignItems: "center" }}
    >
      <BreadCrumbsArrow />
      <span className="h6-medium pointer breadcrumbs-title">
        {collection?.name}
      </span>
    </div>
  );

  const facetBreadcrumb = (
    <div
      onClick={() => updateFilters("facet")}
      style={{ display: "flex", gap: 16, alignItems: "center" }}
    >
      <BreadCrumbsArrow />
      <span className="h6-medium pointer breadcrumbs-title">{facet?.name}</span>
    </div>
  );

  const itemBreadcrumb = (
    <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
      <BreadCrumbsArrow />
      <span className="h6-regular">{itemName}</span>
    </div>
  );

  const breadcrumbs = (
    <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
      <span
        onClick={() => updateFilters()}
        className="h6-medium pointer breadcrumbs-title"
      >
        {t("header.categories")}
      </span>
      {collection ? collectionBreadcrumb : null}
      {facet ? facetBreadcrumb : null}
      {itemBreadcrumb}
    </div>
  );

  return <div style={{ padding: "140px 0px 56px 0px" }}>{breadcrumbs}</div>;
};

export default ItemPageBreadcrumbs;
